import React, { useState } from 'react'
import drawing from "../../media/drawing.png"
import { Link } from 'react-router-dom';
import OtpInput from 'react-otp-input';



const ForgetPassword = () => {
    const [step, setStep] = useState(1); // Initial step
    const [code, setCode] = useState("");
    const handleChange = (code) => setCode(code);



    const handleProceed = () => {
        setStep(step + 1); // Move to the next step
    };

    const renderStepContent = () => {
        switch (step) {
            case 1:
                return (
                    <div className="signin-box cflex">
                        <div className="sign-head forgot" style={{marginBottom:"30px"}}>
                            <h3>OTP Required To Reset Password</h3>
                            <p>An OTP has been sent to your email please check your inbox</p>
                        </div>
                        <div className="sign-input cflex">
                            <OtpInput
                                value={code}
                                onChange={handleChange}
                                numInputs={4}
                                renderSeparator={<span style={{ width: "12px" }}> </span>}
                                isInputNum={true}
                                shouldAutoFocus={true}
                                renderInput={(props) => <input {...props} />}
                                inputStyle={{
                                    border: "0.5px solid #000",
                                    borderRadius: "8px",
                                    width: "55px",
                                    height: "55px",
                                    fontSize: "18px",
                                    color: "#000",
                                    fontWeight: "400",
                                    caretColor: "blue",
                                    backgroundColor:"#EEE"
                                }}
                                focusStyle={{
                                    border: "1px solid #CFD3DB",
                                    outline: "none"
                                }}
                            />
                        </div>
                        <div className="continue" onClick={handleProceed}>Verify</div>

                    </div>
                );
            case 2:
                return (
                    <div className="signin-box cflex">
                        <div className="sign-head forgot">
                            <h3 style={{ marginBottom: "30px" }}>Set New Password.</h3>
                            <div className="sign-input cflex">
                                <input type="text" placeholder='New Password' />
                                <input type="text" placeholder='Confirm New Password' />
                            </div>
                        </div>
                        <Link to={'/'} className="continue">Change</Link>

                    </div>
                );

            default:
                return null;
        }
    };

    return (
        <div className='signin flex'>
            <div className="signin-area flex">
                {renderStepContent()}
            </div>
            <div className="drawing flex">
                <img src={drawing} alt="" />
            </div>

        </div>
    )
}

export default ForgetPassword
