import React, { useRef, useState } from 'react';
import './help.css';
import help from '../../media/help2.svg';
import close from '../../media/close.svg';
import v from '../../media/v.svg';
import axios from 'axios';

const HelpAndSupport = () => {
  const [showOverlay, setShowOverlay] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const fileInputRef = useRef(null);
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    subject: '',
    department: '',
    message: '',
  });

  console.log("form data to ", formData)

  const handleSubmit = () => {
    const formDataToSubmit = new FormData();
    formDataToSubmit.append('name', formData.name);
    formDataToSubmit.append('email', formData.email);
    formDataToSubmit.append('subject', formData.subject);
    formDataToSubmit.append('department', formData.department);
    formDataToSubmit.append('message', formData.message);
    if(selectedFile !== undefined){
      formDataToSubmit.append('file', selectedFile);
    }

    console.log('form data to upload', Object.fromEntries(formDataToSubmit));

    axios
      .post('https://brich.youthevents.in/Cdashboard/submit_form/', formDataToSubmit, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then((response) => {

        setShowOverlay(true); // Show the overlay upon form submission
        setFormData({
          name: '',
          email: '',
          subject: '',
          department: '',
          message: '',
        });
        setSelectedFile(null); // Reset the selected file
      })
      .catch((error) => {
        console.error('Error submitting form:', error);
      });
  };

  const handleclosepopup = () => {
    setShowOverlay(false);
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setSelectedFile(file);
  };

  const handleUploadClick = () => {
    fileInputRef.current.click();
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };
  const [showDropdown, setShowDropdown] = useState(false);
  const [selectedDepartment, setSelectedDepartment] = useState('');

  const departments = ['Sales', 'Support', 'Billing', 'Technical']; // Replace with your department options

  const handleDepartmentSelect = (department) => {
    setSelectedDepartment(department);
    setShowDropdown(false);
    setFormData({
      ...formData,
      department: department,
    });
  };

  return (
    <div className='help cflex'>
      <div className='help-head flex'>
        <img src={help} alt='' />
        <h3>Help & Support</h3>
      </div>
      <div className='form-wrap'>
        <div className='name-email flex'>
          <input
            type='text'
            className='names'
            name='name'
            placeholder='Name'
            value={formData.name}
            onChange={handleInputChange}
          />
          <input
            type='text'
            className='email'
            name='email'
            placeholder='Email Address'
            value={formData.email}
            onChange={handleInputChange}
          />
        </div>
        <div className='subject-dept flex'>
          <input
            type='text'
            className='subject'
            name='subject'
            placeholder='Subject'
            value={formData.subject}
            onChange={handleInputChange}
          />
          <div className="custom-drop">

            <input
              type='text'
              className='dept'
              placeholder='Department'
              value={selectedDepartment}
              readOnly
              onClick={() => setShowDropdown(!showDropdown)}
              style={{ position: "relative", maxWidth: "223px" }}
            />
            <img
              src={v}
              alt=""
              className={`droparr ${showDropdown ? 'rotated' : ''}`}
              onClick={() => setShowDropdown(!showDropdown)}
            />          {showDropdown && (
              <ul className='dropdown-list'>
                {departments.map((department, index) => (
                  <li className='dropli' key={index} onClick={() => handleDepartmentSelect(department)}>
                    {department}
                  </li>
                ))}
              </ul>
            )}
          </div>
        </div>
        <div className='message flex'>
          <textarea
            name='message'
            placeholder='Write Your Message Here..'
            value={formData.message}
            onChange={handleInputChange}
          ></textarea>
        </div>
        <div className='file-upload flex'>
          <input
            type='file'
            onChange={handleFileChange}
            ref={fileInputRef}
            style={{ display: 'none' }}
          />
          <input
            type='text'
            placeholder='Select a File to Attach (optional)'
            value={selectedFile ? selectedFile.name : ''}
            readOnly
          />
          <div className='upload-btn upload-btn1' onClick={handleUploadClick}>
            Upload
          </div>
        </div>
        <div className='accept accept1' onClick={handleSubmit}>
          Submit
        </div>
      </div>

      {showOverlay && (
        <div className='overlay'>
          <div className='overlay-content submit-content cflex'>
            <div className='close close-form' onClick={handleclosepopup}>
              <img src={close} alt='' />
            </div>
            <h3>
              Thank you for submitting your ticket! Our team is on it, and we'll
              get back to you shortly. Your request is important to us.
            </h3>
            <div className='okay' onClick={handleclosepopup}>
              Okay
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default HelpAndSupport;
