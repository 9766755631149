import React, { useState } from 'react'
import "./getaccess.css"
import brich from "../../media/BRICH.svg"
import tik from "../../media/tik.svg"
import bright from "../../media/bright.svg"
import bleft from "../../media/bleft.svg"
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import ThankYoupopup from '../ThankYou'
import axios from 'axios'

const validationSchema = Yup.object().shape({
    name: Yup.string().required('*Name is required'),
    email: Yup.string().email('*Invalid email').required('*Email is required'),
    mobile: Yup.string().matches(/^\d{10}$/, '*Mobile must be 10 digits').required('*Mobile is required'),
    creatorType: Yup.string(),
    socialMediaLink: Yup.string().required('*Link is required'),
    description: Yup.string(),
});

const GetAccess = () => {
    const [showSlideIn, setShowSlideIn] = useState(false);


    const handleGetAccessClick = () => {
        setShowSlideIn(true);
    };
    const setslideoff = () => {
        if (showSlideIn) {
            setShowSlideIn(false)
        }
    }
    const [isSubmitted, setIsSubmitted] = useState(false);


    const initialValues = {
        name: '',
        email: '',
        mobile: '',
        creatorType: '',
        socialMediaLink: '',
        description: '',
    };
    console.log("form data is", initialValues)

    const handleSubmit = async (values, { setSubmitting, resetForm }) => {
        try {
            setSubmitting(true);

            const response = await axios.post('https://api.creator.ind.in/api/creators-ind/', values);
            console.log('Form submitted successfully:', response.data);
            setIsSubmitted(true);

            // Reset the form
            resetForm();

            setSubmitting(false);
        } catch (error) {
            console.error('Error submitting form:', error);
            setSubmitting(false);
        }
    };


    return (
        <div className='gata'>
            <div className="up flex">
                <div className="logo">
                    <img src={brich} alt="" />
                    B RICH
                </div>
            </div>
            <div className="join yblob" onClick={setslideoff}>
                <div className="left">
                    <img src={bright} alt="" className="bright" />
                    <h2>Join Us AT BRICH </h2>
                    <h3>Scale up your Creator Journey.</h3>
                    <p>Being a creator is about bringing your unique ideas and visions to life, whether through art, writing, music, or any form of expression. It's the act of turning imagination into reality and sharing it with the world. And we are here to help you build to bring the professional aspect to your creativity.</p>
                    <img src={bleft} alt="" className="bleft" />
                    <div className="getaccess" onClick={handleGetAccessClick}>
                        Get Access
                    </div>
                </div>
                <div className="right">
                    <h3>Benefits on your way.</h3>
                    <div className="paras">
                        <p><img src={tik} alt="" /><div>  <span>Boost Your Earnings :</span> Turn your influence into income with generous commission rates. </div> </p>
                        <p><img src={tik} alt="" /><div>  <span>Access To Exclusive Brands:</span>  Collaborate with sought-after brands to promote and sell their products. </div> </p>
                        <p><img src={tik} alt="" /><div>  <span>Set Up Your Store:</span>  Create your unique online store and showcase your favorite products. </div> </p>
                        <p><img src={tik} alt="" /><div>  <span>Seamless Transactions:</span> Enjoy secure and hassle-free payment processing </div> </p>


                    </div>

                </div>

            </div>


            <div className={`slide-in-div bblob  ${showSlideIn ? 'open' : ''}`}>
                <h2>Join the Movement.</h2>

                <Formik
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    onSubmit={handleSubmit}
                >
                    {({ isSubmitting }) => (
                        <Form className="form">
                            <div className="name-email2">
                                <div className='error-feild'>

                                    <div className="name2">
                                        Name <div className="line"></div>
                                        <Field type="text" name="name" />
                                    </div>
                                    <ErrorMessage name="name" component="div" className="error" />
                                </div>
                                <div className='error-feild'>


                                    <div className="email">
                                        Email <div className="line"></div>
                                        <Field type="email" name="email" />
                                    </div>
                                    <ErrorMessage name="email" component="div" className="error" />
                                </div>
                            </div>
                            <div className="number-type">

                                <div className='error-feild1'>

                                    <div className="number">
                                        +91 <div className="line"></div>
                                        <Field
                                            type="number"
                                            name="mobile"
                                            placeholder='Continue with Mobile Number'
                                            maxLength='10'
                                        />
                                    </div>
                                    <ErrorMessage name="mobile" component="div" className="error" />
                                </div>

                                <div className="type2">
                                    Creator Type <div className="line"></div>
                                    <Field
                                        type="text"
                                        name="creatorType"
                                        placeholder='Fashion Influencer'
                                    />
                                    <ErrorMessage name="creatorType" component="div" className="error" />
                                </div>
                            </div>
                            <div className="media">
                                Social Media Link <div className="line"></div>
                                <Field
                                    type="text"
                                    name="socialMediaLink"
                                    placeholder='Social Link'
                                />
                                <ErrorMessage name="socialMediaLink" component="div" className="error" />
                            </div>
                            <div className="desc">
                                <Field
                                    as="textarea"
                                    name="description"
                                    placeholder='Say Something About Yourself ....'
                                />
                                <ErrorMessage name="description" component="div" className="error" />
                            </div>
                            {isSubmitted && <ThankYoupopup onClose={() => setIsSubmitted(false)} />}

                            <button type="submit" className="submit" onClick={handleSubmit}>
                                {isSubmitting ? "Submitting..." : "Submit"}
                            </button>
                        </Form>
                    )}
                </Formik>

            </div>



        </div>
    )
}

export default GetAccess
