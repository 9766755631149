import React, { useEffect, useRef, useState } from 'react';
import Chart from 'chart.js/auto';
import 'chartjs-adapter-date-fns';
import pro from "../../media/pro.png"
import close from "../../media/close.svg"
import down from "../../media/down.svg"
import upside from "../../media/upside.svg"
import './dashboard.css';
import axios from "axios"
import JSZip from 'jszip';
import { saveAs } from 'file-saver';

const Dashboard = () => {

  const pieChartRef = useRef(null);
  const lineChartRef = useRef(null);
  const [bankbalance, setBankbalance] = useState(0)
  const [revenue, setRevenue] = useState(0)
  const [error, setError] = useState(null);



  const [followerData, setFollowerData] = useState(null); // State to store follower data

  useEffect(() => {
    const userpofile = localStorage.getItem("userProfile")

    const userid = userpofile ? JSON.parse(userpofile).id : null;

    const fetchFollowerData = async () => {

      const userpofile = localStorage.getItem("userProfile")

      const userid = userpofile ? JSON.parse(userpofile).id : null;
      try {
        const response = await axios.get('https://brich.youthevents.in/Cdashboard/get-userfollower-counts/', {
          headers: {
            'user': userid // Pass user ID in request headers
          }
        });
        setFollowerData(response.data);
        console.log("object,", response.data)
        setError(null);
      } catch (error) {
        setError(error.response.data.error || 'Something went wrong');
        setFollowerData(null);
      }
    };

    if (userid) {
      fetchFollowerData();
    }
  }, []);



  const userDetail = [
    {
      totalRevenue: revenue,
      currentBalance: bankbalance,
      JoinDate: '25 febuary 2022',
    },
  ];

  useEffect(() => {
    const getBankDetails = async () => {
      const userpofile = localStorage.getItem("userProfile")

      const userid = userpofile ? JSON.parse(userpofile).id : null;
      try {
        const response = await axios.get(`https://brich.youthevents.in/Cdashboard/api/creator/${userid}/bank-details/`, {
          headers: {
            'user': userid,
          }
        }
        );

        if (response.status === 200) {
          const bankDetails = response.data;
          console.log('Bank Details: isssssss', bankDetails);
          setBankbalance(bankDetails.bank_balance)
          setRevenue(bankDetails.total_revenue)
          // Handle the bank details received from the API
        } else {
          console.error('Failed to fetch bank details');
          // Handle the error response
        }
      } catch (error) {
        console.error('An error occurred:', error);
        // Handle network errors or exceptions
      }
    };

    getBankDetails();
  }, []);

  const createChart = () => {
    if (userDetail.length > 0) {
      const totalRevenue = revenue;
      const currentBalance = bankbalance;

      // const totalRevenue = userDetail[0].totalRevenue;
      // const currentBalance = userDetail[0].currentBalance;

      const pieCtx = pieChartRef.current.getContext('2d');
      const lineCtx = lineChartRef.current.getContext('2d');

      // Destroy the previous pie chart instance if it exists
      if (pieChartRef.current && pieChartRef.current.chartInstance) {
        pieChartRef.current.chartInstance.destroy();
      }

      pieChartRef.current.chartInstance = new Chart(pieCtx, {
        type: 'doughnut',
        data: {
          datasets: [
            {
              data: [totalRevenue, currentBalance],
              backgroundColor: ['#5AB581', '#B88D00'],
              borderColor: ['#5AB581', '#B88D00'],
              borderWidth: [2.611, 5.221],
            },
          ],
        },
        options: {
          cutout: '99%',
          height: 127,
          width: 127,
        },
      });

      // Destroy the previous line chart instance if it exists
      if (lineChartRef.current && lineChartRef.current.chartInstance) {
        lineChartRef.current.chartInstance.destroy();
      }

      if (!followerData || followerData.length === 0) {
        // If no follower data is present, create an empty chart instance
        const lineCtx = lineChartRef.current.getContext('2d');
        lineChartRef.current.chartInstance = new Chart(lineCtx, {
          type: 'line',
          data: {
            datasets: [
              {
                label: 'Followers Growth',
                data: [],
                borderColor: '#5AB581',
                backgroundColor: lineCtx.createLinearGradient(0, 0, 0, 200),
                borderWidth: 0.5,
                pointRadius: 0.5,
                fill: true,
                borderCapStyle: 'round', // Set line end style to round
              },
            ],
          },
          options: {
            scales: {
              x: {
                type: 'time',
                time: {
                  unit: 'day',
                  tooltipFormat: 'MMM dd, yyyy',
                  displayFormats: {
                    day: 'MMM dd',
                  },
                },
                ticks: {
                  color: "#959595",
                  font: {
                    size: 13,
                    family: "Inter",
                    weight: 600,
                  },
                  maxTicksLimit: 3,
                },
                grid: {
                  display: false,
                },
                beginAtZero: false,
              },
              y: {
                ticks: {
                  color: '#B88D00',
                  font: {
                    size: 13,
                    family: "Inter",
                    weight: 600,
                  },
                  maxTicksLimit: 6
                },
                beginAtZero: false,
              },
            },
            plugins: {
              filler: {
                propagate: false, // To fill only below the line, not to the zero axis
              },
            },
          },
        });

        const gradient = lineChartRef.current.chartInstance.data.datasets[0].backgroundColor;
        gradient.addColorStop(0.0833, '#5AB581'); // 8.33% in decimal
        gradient.addColorStop(1, 'rgba(90, 181, 129, 0.09)'); // 100%
      } else {
        // If follower data is present, create the chart with data
        const data = followerData.map(entry => ({
          x: new Date(entry.date), // Date from followerData
          y: entry.follower_count, // Follower count from followerData
        }));

        const lineCtx = lineChartRef.current.getContext('2d');
        lineChartRef.current.chartInstance = new Chart(lineCtx, {
          type: 'line',
          data: {
            datasets: [
              {
                label: 'Followers Growth',
                data: data,
                borderColor: '#5AB581',
                backgroundColor: lineCtx.createLinearGradient(0, 0, 0, 200),
                borderWidth: 0.5,
                pointRadius: 0.5,
                fill: true,
                borderCapStyle: 'round', // Set line end style to round
              },
            ],
          },
          options: {
            scales: {
              x: {
                type: 'time',
                time: {
                  unit: 'day',
                  tooltipFormat: 'MMM dd, yyyy',
                  displayFormats: {
                    day: 'MMM dd',
                  },
                },
                ticks: {
                  color: "#959595",
                  font: {
                    size: 13,
                    family: "Inter",
                    weight: 600,
                  },
                  maxTicksLimit: 3,
                },
                grid: {
                  display: false,
                },
                beginAtZero: false,
              },
              y: {
                ticks: {
                  color: '#B88D00',
                  font: {
                    size: 13,
                    family: "Inter",
                    weight: 600,
                  },
                  maxTicksLimit: 6
                },
                beginAtZero: false,
              },
            },
            plugins: {
              filler: {
                propagate: false, // To fill only below the line, not to the zero axis
              },
            },
          },
        });

        const gradient = lineChartRef.current.chartInstance.data.datasets[0].backgroundColor;
        gradient.addColorStop(0.0833, '#5AB581'); // 8.33% in decimal
        gradient.addColorStop(1, 'rgba(90, 181, 129, 0.09)'); // 100%
      }
    }
  }

  useEffect(() => {
    if (revenue !== 0 || bankbalance !== 0 || followerData || revenue == 0 || bankbalance == 0) {
      createChart();
    }
  }, [revenue, bankbalance, followerData]);



  const [showOverlay, setShowOverlay] = useState(false);
  const [selectedRequest, setSelectedRequest] = useState(null);
  const [timer, setTimer] = useState({
    hours: 24,
    minutes: 0,
    seconds: 0,
  });

  useEffect(() => {
    fetchCampaigns();
  }, []);

  const [campaigns, setCampaigns] = useState([]);

  const [expandedItems, setExpandedItems] = useState(opportunities.map(() => false));

  const handleDropdownClick = (index) => {
    setExpandedItems(prevExpandedItems => {
      const newExpandedItems = [...prevExpandedItems];
      newExpandedItems[index] = !newExpandedItems[index];
      return newExpandedItems;
    });
  };
  useEffect(() => {
    const countdownIntervals = campaigns.map(campaign => {
      return setInterval(() => {
        updateTimer(campaign.id);
      }, 1000);
    });

    return () => {
      countdownIntervals.forEach(interval => clearInterval(interval));
    };
  }, [campaigns]);

  const fetchCampaigns = () => {
    const userEmail = userpofile ? JSON.parse(userpofile).email : null;

    axios.get('https://brich.youthevents.in/Bdashboard/retrieve_campaigns/', {
      headers: {
        'user': userEmail,
      }
    }).then(response => {
      const updatedCampaigns = response.data.map(campaign => ({
        ...campaign,
        timer: calculateTimer(campaign.deadline_time)
      }));
      setCampaigns(updatedCampaigns);
    }).catch(error => {
      console.error('Error fetching campaigns:', error);
    });
  };

  const calculateTimer = (deadlineTime) => {
    const deadline = new Date(deadlineTime).getTime();
    const now = new Date().getTime();
    const timeDifference = deadline - now;

    if (timeDifference <= 0) {
      return { days: 0, hours: 0, minutes: 0, seconds: 0 };
    }

    const days = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
    const hours = Math.floor((timeDifference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    const minutes = Math.floor((timeDifference % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((timeDifference % (1000 * 60)) / 1000);

    return { days, hours, minutes, seconds };
  };

  const updateTimer = (campaignId) => {
    setCampaigns(prevCampaigns => {
      return prevCampaigns.map(campaign => {
        if (campaign.id === campaignId) {
          return {
            ...campaign,
            timer: calculateTimer(campaign.deadline_time)
          };
        }
        return campaign;
      });
    });
  };



  const formatTime = value => {
    return value < 10 ? `0${value}` : `${value}`;
  };
  const userpofile = localStorage.getItem("userProfile")

  const handleAcceptClick = (index) => {
    setShowOverlay(true);
    setSelectedRequest(campaigns[index])
  };

  const handleAcceptConfirm = () => {
    if (selectedRequest) {
      const campaignId = selectedRequest.id;
      const userEmail = userpofile ? JSON.parse(userpofile).id : null;

      console.log("creator email", userEmail)

      axios.post(`https://brich.youthevents.in/Bdashboard/join-campaign/${campaignId}/`, {

        creator_email: userEmail,
      })
        .then(response => {
          // Handle successful join request
          console.log('Joined campaign successfully:', response.data);
          setShowOverlay(false);
          setSelectedRequest(null);
          // Perform actions after successfully joining the campaign
        })
        .catch(error => {
          // Handle join request error
          console.error('Error joining campaign:', error.message);
          // Perform error handling or display an error message to the user
        });
    }

    // Add your logic for accepting the request here
  };
  const getStatus = (index) => {
    const selectedCampaign = campaigns[index];
    const campaignId = selectedCampaign.id;
    const userEmail = userpofile ? JSON.parse(userpofile).id : null;

    axios.get(`https://brich.youthevents.in/Bdashboard/join-campaign/${campaignId}/?creator_email=${userEmail}`)
      .then(response => {
        const updatedCampaigns = [...campaigns];
        updatedCampaigns[index] = {
          ...selectedCampaign,
          status: response.data.status // Assuming the API response contains the status
        };
        setCampaigns(updatedCampaigns);
      })
      .catch(error => {
        console.error('Error fetching campaign status:', error.message);
      });
  };


  const handleAcceptNotSure = () => {
    setShowOverlay(false);
    setSelectedRequest(null);
    // Handle actions when the user is not sure about accepting the request
  };



  console.log("campaigns  are", campaigns)


  // const fetchCampaigns = () => {

  //   const userEmail = userpofile ? JSON.parse(userpofile).email : null;

  //   axios.get('https://brich.youthevents.in/Bdashboard/retrieve_campaigns/', {
  //     headers: {
  //       'user': userEmail,
  //     }
  //   }).then(response => {
  //     setCampaigns(response.data);
  //   })
  //     .catch(error => {
  //       console.error('Error fetching campaigns:', error);
  //     });
  // };



  const handleDownloadClick = async (campaignId) => {
    try {
      const response = await fetch(`https://brich.youthevents.in/Bdashboard/api/campaign-files/${campaignId}/`);
      if (response.ok) {
        const data = await response.json();
        if (data && data.files.length > 0) {
          downloadFiles(data.files);
        } else {
          console.log('No files found for the specified campaign ID.');
        }
      } else {
        console.error('Failed to fetch campaign files');
      }
    } catch (error) {
      console.error('Error fetching campaign files:', error);
    }
  };

  const downloadFiles = async (files) => {
    const downloadPromises = files.map(file => {
      return fetch(file.file_url)
        .then(async (response) => {
          if (response.ok) {
            const blob = await response.blob();
            const filename = file.file_name;
            const fileExt = filename.split('.').pop();
            saveAs(blob, filename); // Trigger download for each file
          } else {
            console.error(`Failed to download ${file.file_name}`);
          }
        })
        .catch((error) => {
          console.error(`Error downloading ${file.file_name}:`, error);
        });
    });

    await Promise.all(downloadPromises);
  };


  return (
    <div className="dashboard cflex">
      <div className="charts flex">
        <div className="revenue cflex">
          <div className="chart-stat">

            <canvas className='pie-chart' ref={pieChartRef} width="127" height="400" ></canvas>
            <div className="revenue-details cflex">
              <div className="rupee">₹</div>
              <div className="revenu">{userDetail[0].totalRevenue}</div>
              <div className="current">{userDetail[0].currentBalance}</div>
            </div>
          </div>

          <div className="indicator cflex">
            <div className="total-revenue"> <h3>Total Revenue</h3> <div></div></div>
            <div className="total-current"> <h3>Current Balance</h3> <div></div></div>
          </div>
        </div>
        <div className="growth cflex">
          {followerData?.length > 1 ? (
            <>
              {/* Content to render when followerData length is greater than 1 */}
            </>
          ) : (
            <>
              <h3 className='noab1'>Enough Data Not Available</h3>
            </>
          )}
          <div className="growth-over">Growth Over Time</div>
          <canvas className='line-chart' ref={lineChartRef} width="400" height="200" ></canvas>
        </div>
      </div>
      <div className="available-oppo flex">
        <h3>Available Opportunities </h3>
      </div>
      {campaigns.length == 0 ? (
        <>
          <h3 className='noab'>new campaigns will be available soon </h3>
        </>
      ) : (
        <div className="oppotunities cflex">
          {campaigns.map((oppotunity, index) => (
            <>
              <div className={`request oppotunity flex ${expandedItems[index] ? 'expanded' : ''}`} >
                <div className="name">
                  <img src={pro} alt="" />
                  <h3>{oppotunity.creator_brand}</h3>
                </div>
                <div className="Campaignname">
                  {oppotunity.campaign_name}
                </div>
                <div className="time-left cflex">
                  <div className="left">Time Left</div>
                  <div className="time flex">

                  <div className="hour">{formatTime(oppotunity.timer.days)}</div>
                    :
                    <div className="hour">{formatTime(oppotunity.timer.hours)}</div>
                    :
                    <div className="min">{formatTime(oppotunity.timer.minutes)}</div>
                    :
                    <div className="sec">{formatTime(oppotunity.timer.seconds)}</div>
                  </div>
                </div>
                <div className="content-payout cflex">
                  <div className="payout"> <span className="black">Payout :</span> {Math.floor(oppotunity.general_payout)} ₹</div>
                  <div className="content"> <span className="black"> Content :</span> {oppotunity.content} </div>

                </div>
                <div className="dropdown" onClick={() => { handleDropdownClick(index); getStatus(index); }}>
                  <img src={expandedItems[index] ? upside : down} alt="" />
                </div>
                <div className="eligibility">
                  <h3>Eligibility CRITERIA :</h3>
                  <div className="min-follower"><span className="bold1">Followers :</span>{Math.floor(oppotunity.eligible_followers)} +</div>
                  {oppotunity.eligible_category && <div className="category-req"><span className="bold1">Category :</span>{oppotunity.eligible_category}</div>}

                </div>
                {expandedItems[index] && (
                  <div className="additional-content cflex">
                    <p>
                      <span className="black">Task Description :</span>
                      {oppotunity.campaign_description}
                    </p>
                    <div className="type-goal flex">
                      <div className="type"><span className="black">Content Type :</span>{oppotunity.content_type}</div>
                      <div className="type"><span className="black">Goal :</span> {oppotunity.goal}</div>
                    </div>
                    {oppotunity.content == "Provided" && (

                      <div className="download download-request" onClick={() => handleDownloadClick(oppotunity.id)}>
                        Download Material
                      </div>
                    )}
                    <div className="accept-div flex">
                      {oppotunity.status ? (
                        <div className="accept">Requested</div>
                      ) : (
                        <div className="accept" onClick={() => handleAcceptClick(index)}>Request</div>
                      )}

                    </div>

                  </div>
                )}

              </div>
              <div className={`mobile-request ${expandedItems[index] ? 'expanded' : ''}`} key={oppotunity.id}>
                <div className="mobile-heading flex">
                  <div className="name">
                    <img src={pro} alt="" />
                    <h3>{oppotunity.creator_brand}</h3>
                  </div>
                  <div className="dropdown" onClick={() => { handleDropdownClick(index); getStatus(index); }}>
                    <img src={down} alt="" />
                  </div>
                </div>

                <div className="Campaignname">
                  {oppotunity.campaign_name}
                </div>
                <div className="time-left cflex">
                  <div className="left">Time Left</div>
                  <div className="time flex">

                  <div className="hour">{formatTime(oppotunity.timer.days)}</div>
                    :
                    <div className="hour">{formatTime(oppotunity.timer.hours)}</div>
                    :
                    <div className="min">{formatTime(oppotunity.timer.minutes)}</div>
                    :
                    <div className="sec">{formatTime(oppotunity.timer.seconds)}</div>
                  </div>
                </div>
                <div className="content-payout cflex">
                  <div className="payout"> <span className="black">Payout :</span> {oppotunity.general_payout} ₹</div>
                  <div className="content"> <span className="black"> Content :</span> {oppotunity.content} </div>

                </div>

                <div className="eligibility">
                  <h3>Eligibility CRITERIA :</h3>
                  <div className="min-follower"><span className="bold1">Followers :</span>{oppotunity.eligible_followers}</div>
                  <div className="category-req"><span className="bold1">Category :</span>{oppotunity.eligible_category}</div>

                </div>


                {expandedItems[index] && (
                  <div className="additional-content cflex">
                    <p>
                      <span className="black">Task Description :</span>
                      {oppotunity.campaign_description}
                    </p>
                    <div className="type-goal flex">
                      <div className="type"><span className="black">Content Type :</span>{oppotunity.content_type}</div>
                      <div className="type"><span className="black">Goal :</span> {oppotunity.goal}</div>
                    </div>
                    {oppotunity.content == "Provided" && (

                      <div className="download download-request" onClick={() => handleDownloadClick(oppotunity.id)}>
                        Download Material
                      </div>
                    )}

                    <div className="accept-div flex">
                      {oppotunity.status  ? (
                        <div className="accept">Requested</div>
                      ) : (
                        <div className="accept" onClick={() => handleAcceptClick(index)}>Request</div>
                      )}
                    </div>

                  </div>
                )}


              </div>

            </>
          ))}
          {showOverlay && selectedRequest && (
            <div className="overlay">
              <div className="overlay-content cflex">
                <div className="close" onClick={handleAcceptNotSure}><img src={close} alt="" /></div>
                <h3>Are you Sure ? You want to Request to Join Campaign?</h3>
                <div className="buttons">
                  <div className='not-sure' onClick={handleAcceptNotSure}>Not Sure</div>
                  <div className='accept' onClick={handleAcceptConfirm}>Request</div>
                </div>
              </div>
            </div>
          )}

        </div>

      )}


    </div>
  );
};

export default Dashboard;
const opportunities = [
  {
    brandName: "Wildinz",
    campaignName: "WILDINZ LEAD GENERATION",
    payout: "4000",
    content: "provided",
    contentType: "reels",
    goal: "Leads Generation",
    taskDescription: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
    eligibilityCriteria: " min 70K",
    category: "entertainment"
  },
  // Additional data entries
  {
    brandName: "BrandXYZ",
    campaignName: "XYZ Campaign",
    payout: "2500",
    content: "not provided",
    contentType: "images",
    goal: "Brand Awareness",
    taskDescription: "Task description for XYZ Campaign.",
    eligibilityCriteria: "min 50K",
    category: "fashion"
  },
  {
    brandName: "AnotherBrand",
    campaignName: "Another Campaign",
    payout: "6000",
    content: "provided",
    contentType: "videos",
    goal: "Sales Conversion",
    taskDescription: "Task description for Another Campaign.",
    eligibilityCriteria: "min 100K",
    category: "technology"
  },
  // Add more entries as needed
];
