import React, { useState } from 'react';
import './sign.css';
import drawing from '../../media/drawing.png';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { setUserAuthenticated, setUserProfile } from '../../redux/filterSlice';
import { useDispatch } from 'react-redux'; // Importing useDispatch
import brich from "../../media/BRICH.svg"


const Signin = ({ setIsUserAuthenticated }) => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const dispatch = useDispatch(); // Getting the dispatch function from react-redux

  const setAuthToken = (token) => {
    if (token) {
      axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    } else {
      delete axios.defaults.headers.common['Authorization'];
    }
  };


  const handleContinue = async () => {
    try {
      const response = await axios.post('https://brich.youthevents.in/Cdashboard/sign-in/', {
        email: username,
        password: password
      });

      // Assuming the backend responds with a success message upon successful login
      if (response.status === 200) {
        // Signin component after successful login
        dispatch(setUserAuthenticated(true));
        dispatch(setUserProfile(response.data.user));

        // Save authentication status and user profile in localStorage
        localStorage.setItem('isUserAuthenticated', 'true');
        localStorage.setItem('userProfile', JSON.stringify(response.data.user));

        const { token, user } = response.data; // Assuming the backend returns a token and user data upon successful login

        // Save the token in local storage or cookies
        localStorage.setItem('token', token);
        localStorage.setItem('userProfile', JSON.stringify(user));
        setAuthToken(token);


        window.location.reload()
      } else {
        // Handle login failure, show error messages, etc.
        console.log('Login failed');
      }
    } catch (error) {
      // Handle errors if the request fails
      console.error('Error while signing in:', error);
    }
  };
  return (
    <>
      <div className="up flex">
        <div className="logo">
          <img src={brich} alt="" />
          B RICH
        </div>
      </div>
      <div className="signin flex yblob">
        <div className="signin-area flex">
          <div className="signin-box cflex">
            <div className="sign-head">
              <h3>Sign In</h3>
              <div className="sign-input cflex">
                <input
                  type="text"
                  placeholder="Username / Email"
                  value={username}
                  onChange={(e) => setUsername(e.target.value)}
                />
                <input
                  type="password"
                  placeholder="Password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
                <Link to={'/forgot-password'} className="forget-pass">
                  forgot password ?
                </Link>
              </div>
            </div>
            <div className="continue" onClick={handleContinue}>
              Continue
            </div>
          </div>
        </div>
        <div className="drawing flex">
          <div className="welcomme">
          Welcome  
          </div>
          <div className="cd">
          BRICH’S CREATOR DASHBOARD
          </div>

          <img src={drawing} alt="" />
        </div>
      </div>
      <div className="bottombar flex">
        <div className='btext'><Link to={'/get-creator-access'} >Click Here</Link>, To Get Creator Dashboard Access</div>

      </div>
    </>

  );
};

export default Signin;
