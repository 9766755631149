import React, { useEffect, useState } from 'react'
import "./navbar.css"
import brich from "../../media/BRICH.svg"
import avatar from "../../media/place.jpg"
import noti from "../../media/notification.svg"
import bell from "../../media/bell.svg"
import bnoti from "../../media/bnoti.svg"
import drop from "../../media/drop.svg"
import dash from "../../media/dashboard.svg"
import request from "../../media/request.svg"
import help from "../../media/help.svg"
import campaign from "../../media/campaign.svg"
import gdash from "../../media/gdashboard.svg"
import grequest from "../../media/grequest.svg"
import ghelp from "../../media/ghelp.svg"
import profile from "../../media/profile.svg"
import logout from "../../media/logout.svg"
import gcampaign from "../../media/gcampaign.svg"
import dot from "../../media/dot.svg"
import axios from "axios"

const Navbar = ({ setActiveComponent }) => {
    const [activeItem, setActiveItem] = useState('Dashboard');
    const [showDropdown, setShowDropdown] = useState(false);
    const [showNotification, setShowNotification] = useState(false); // Manage notification screen visibility
    const [isFullHeight, setIsFullHeight] = useState(false); // Manage full-height notification screen
    const [notifications, setNotifications] = useState(dummyNotifications.slice(0, 4)); // Initial 4 notifications
    const [viewAllNotifications, setViewAllNotifications] = useState(false); // Manage view all notifications



    // Function to handle notification icon click
    const handleNotificationClick = () => {

        
        setShowNotification(prevShowNotification => !prevShowNotification);
        setIsFullHeight(false);
        document.body.classList.toggle('freeze-scroll'); // Toggle body freeze-scroll class

        if (!showNotification) {
            setNotifications(notifications.slice(0, 4));
            setViewAllNotifications(false);
        }
        fetchNotifications();

    };

    // Function to handle "View All" button click
  

    const closeNotification = () => {
        setShowNotification(false);
        setIsFullHeight(false);
        document.body.classList.remove('freeze-scroll'); // Remove body freeze-scroll class
    };

    const handleDropdownClick = () => {
        setShowDropdown(!showDropdown); // Toggle dropdown visibility
    };

    const handleProfileClick = () => {
        setShowDropdown(false); // Hide dropdown after an option is selected (e.g., redirect to profile)
    };

    

    const handleLogoutClick = () => {
        // Logic for handling logout click
        axios.post('https://brich.youthevents.in/Cdashboard/api/logout/')  // Use the URL defined for logout
        .then(response => {
            console.log('Logged out successfully');
            localStorage.removeItem('isUserAuthenticated');
            localStorage.removeItem("token")
            localStorage.removeItem("userProfile")
            setShowDropdown(false); // Hide dropdown after an option is selected (e.g., log out the user)
            window.location.reload()
        })
        .catch(error => {
            // Handle errors
            console.error('Error logging out:', error);
        });
       
       
    };
    const parseuser = localStorage.getItem("userProfile")
    const user = JSON.parse(parseuser)

    console.log("user is",user.id)


    const itemImageMap = {
        'Dashboard': { normal: dash, highlighted: gdash },
        'Requests': { normal: request, highlighted: grequest },
        'myCampaign': { normal: campaign, highlighted: gcampaign },
        'Help': { normal: help, highlighted: ghelp }
    };

    const fetchNotifications = async () => {
        try {
          const response = await fetch('https://brich.youthevents.in/Bdashboard/api/get-notifications/', {
            method: 'GET',
            headers: {
            //   'Authorization': `Bearer ${accessToken}`, 
              'Content-Type': 'application/json',
              "user" : user.id,
            }
          });
      
          if (response.ok) {
            const notifications = await response.json();
            // Handle the retrieved notifications (e.g., display them in UI)
            // displayNotifications(notifications);

            console.log("notification is",notifications)
            setNotifications(notifications)
          } else {
            // Handle error response from the server
            console.error('Failed to fetch notifications');
          }
        } catch (error) {
          console.error('Error fetching notifications:', error);
        }
      };
      
      
    useEffect(() => {
        
        fetchNotifications();
    }, []);

    const handleViewAllClick = () => {
        fetchNotifications();

        setIsFullHeight(!isFullHeight);
        setViewAllNotifications(true);
        setNotifications(notifications);
    };

    const [profilepic, setProfilepic] = useState(null);

    const fetchUserProfile = () => {
        const accessToken = localStorage.getItem('token');
        const userProfile = JSON.parse(localStorage.getItem('userProfile')); // Parse the userProfile JSON

        const userpofile = localStorage.getItem("userProfile")
        const userid = userpofile ? JSON.parse(userpofile).id : null;

        axios.get(`https://brich.youthevents.in/Cdashboard/user-profile/`, {


            headers: {
                'user': userid,
            }


            // headers: {
            //     'Authorization': `Bearer ${accessToken}`,
            //     'user': userProfile.id,
            // },
        })
            .then(response => {
                if (response.status === 200) {
                    // Handle successful response - profile fetched
                    console.log('User Profile:', response.data);
                    setProfilepic(response.data.profile_picture)
                } else {
                    // Handle error scenarios
                    console.error('Failed to fetch user profile');
                }
            })
            .catch(error => {
                // Handle network errors
                console.error('Error:', error);
            });
    };

    useEffect(() => {

        fetchUserProfile();
    }, []);


   
    return (
        <div className='navbar cflex'>
            <div className="up flex">
                <div className="logo">
                    <img src={brich} alt="" />
                    B RICH
                </div>
                <div className="group flex">
                    <div className="notification" onClick={handleNotificationClick}>
                        <img src={ showNotification ? bnoti : bell} alt="" />
                    </div>
                    <div className="profile flex">
                        <img src={profilepic ? profilepic : avatar} alt="" />
                        <h3>{user.name}</h3>
                    </div>
                    <div className="drop" onClick={handleDropdownClick}>
                        <img src={drop} alt="" />
                        {showDropdown && (
                            <div className="dropdown-content">
                                <div to={'/user-profile'} className="dropdown-option" onClick={() => { setActiveComponent('Profile'); setActiveItem('Profile'); handleProfileClick(); }}>
                                    Profile
                                    <img src={profile} alt="" />
                                </div>
                                <div className="dropdown-option" onClick={handleLogoutClick}>
                                    Logout
                                    <img src={logout} alt="" />

                                </div>
                            </div>
                        )}
                    </div>
                </div>
                <div className={`notification-screen ${isFullHeight ? 'full-height' : ''} ${showNotification ? 'open' : ''}`}>
                    {/* Your notification content goes here */}
                    <div className={`notification-content  ${showNotification ? 'open' : ''}`}>
                        <div className="sample-notifications">
                            {notifications.map(notification => (
                                <div key={notification.id} className="notification-item flex">
                                    <div className="notificat cflex">
                                        <h3>{notification.heading}</h3>
                                        <p>{notification.description}</p>
                                    </div>
                                </div>
                            ))}

                        </div>
                        {!viewAllNotifications && (
                            <div className="view-all-button flex">
                                <div className="viewall" onClick={handleViewAllClick}>
                                    View All
                                </div>
                            </div>
                        )}
                    </div>
                </div>

            </div>
            {showNotification && (

                <div className="noti-overlay"></div>

            )}
            <div className="down flex">
                <div className={`nav-item ${activeItem === 'Dashboard' ? 'clicked' : ''}`}
                    onClick={() => { setActiveComponent('Dashboard'); setActiveItem('Dashboard'); }}
                >
                    <img src={activeItem === 'Dashboard' ? itemImageMap['Dashboard'].highlighted : itemImageMap['Dashboard'].normal} alt="" />
                    <h3>Dashboard</h3>
                    {activeItem === 'Dashboard' && <img className="dot" src={dot} alt="" />}

                </div>

                <div className={`nav-item ${activeItem === 'Requests' ? 'clicked' : ''}`}
                    onClick={() => { setActiveComponent('Requests'); setActiveItem('Requests'); }}
                >
                    <img src={activeItem === 'Requests' ? itemImageMap['Requests'].highlighted : itemImageMap['Requests'].normal} alt="" />
                    <h3>Requests</h3>
                    {activeItem === 'Requests' && <img className="dot" src={dot} alt="" />}

                </div>
                <div className={`nav-item ${activeItem === 'myCampaign' ? 'clicked' : ''}`}
                    onClick={() => { setActiveComponent('myCampaign'); setActiveItem('myCampaign'); }}
                >
                    <img src={activeItem === 'myCampaign' ? itemImageMap['myCampaign'].highlighted : itemImageMap['myCampaign'].normal} alt="" />
                    <h3>MY CAMPAIGN</h3>
                    {activeItem === 'myCampaign' && <img className="dot" src={dot} alt="" />}

                </div>


                <div className={`nav-item ${activeItem === 'Help' ? 'clicked' : ''}`}
                    onClick={() => { setActiveComponent('Help'); setActiveItem('Help'); }}
                >
                    <img src={activeItem === 'Help' ? itemImageMap['Help'].highlighted : itemImageMap['Help'].normal} alt="" />
                    <h3>Help & Support</h3>
                    {activeItem === 'Help' && <img className="dot" src={dot} alt="" />}

                </div>

            </div>

        </div>
    )
}

export default Navbar

const dummyNotifications = [
    { id: 1, heading: 'Notification Heading 1', description: 'Description 1' },
    { id: 2, heading: 'Notification Heading 2', description: 'Description 2' },
    { id: 3, heading: 'Notification Heading 3', description: 'Description 3' },
    { id: 4, heading: 'Notification Heading 4', description: 'Description 4' },
    { id: 5, heading: 'Notification Heading 5', description: 'Description 5' },
    { id: 6, heading: 'Notification Heading 6', description: 'Description 6' },
    { id: 7, heading: 'Notification Heading 7', description: 'Description 7' },
    { id: 8, heading: 'Notification Heading 8', description: 'Description 8' },
    { id: 9, heading: 'Notification Heading 9', description: 'Description 9' },
    { id: 10, heading: 'Notification Heading 10', description: 'Description 10' },
    { id: 11, heading: 'Notification Heading 11', description: 'Description 11' },
    { id: 12, heading: 'Notification Heading 12', description: 'Description 12' },
    { id: 13, heading: 'Notification Heading 13', description: 'Description 13' },
    { id: 14, heading: 'Notification Heading 14', description: 'Description 14' },
    { id: 15, heading: 'Notification Heading 15', description: 'Description 15' },
    { id: 16, heading: 'Notification Heading 16', description: 'Description 16' },
    { id: 17, heading: 'Notification Heading 17', description: 'Description 17' },
    { id: 18, heading: 'Notification Heading 18', description: 'Description 18' },
    { id: 19, heading: 'Notification Heading 19', description: 'Description 19' },
    { id: 20, heading: 'Notification Heading 20', description: 'Description 20' },
];
