import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  userAuthenticated: false,
  userProfile: null, // To store user profile data
};

const filterSlice = createSlice({
  name: "filters",
  initialState,
  reducers: {
    setUserAuthenticated: (state, action) => {
      state.userAuthenticated = action.payload;
    },
    setUserProfile: (state, action) => {
      state.userProfile = action.payload;
    },
  },
});

export const { setUserAuthenticated, setUserProfile } = filterSlice.actions;

export default filterSlice.reducer;
