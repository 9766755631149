import React, { useEffect, useState } from 'react'
import "./request.css"
import pro from "../../media/pro.png"
import close from "../../media/close.svg"
import down from "../../media/down.svg"
import axios from "axios"
import JSZip from 'jszip';
import { saveAs } from 'file-saver';

const Request = () => {
  const [showOverlay, setShowOverlay] = useState(false);
  const [selectedRequest, setSelectedRequest] = useState(null);
  const [timer, setTimer] = useState({
    hours: 24,
    minutes: 0,
    seconds: 0,
  });


  const [campaigns, setCampaigns] = useState([]);


  const handleDropdownClick = (index) => {
    setExpandedItems(prevExpandedItems => {
      const newExpandedItems = [...prevExpandedItems];
      newExpandedItems[index] = !newExpandedItems[index];
      return newExpandedItems;
    });
  };

  useEffect(() => {
    fetchCampaigns();
  }, []);

  useEffect(() => {
    const countdownIntervals = campaigns.map(campaign => {
      return setInterval(() => {
        updateTimer(campaign.id);
      }, 1000);
    });

    return () => {
      countdownIntervals.forEach(interval => clearInterval(interval));
    };
  }, [campaigns]);

  const fetchCampaigns = () => {
    const userid = userpofile ? JSON.parse(userpofile).id : null;
    axios.get('https://brich.youthevents.in/Bdashboard/get-all-request-data/', {
      headers: {
        'user': userid,
      }
    }).then(response => {
      const updatedCampaigns = response.data.map(campaign => ({
        ...campaign,
        timer: calculateTimer(campaign.deadline_time)
      }));
      setCampaigns(updatedCampaigns);
    }).catch(error => {
      console.error('Error fetching campaigns:', error);
    });
  };

  const calculateTimer = (deadlineTime) => {
    const deadline = new Date(deadlineTime).getTime();
    const now = new Date().getTime();
    const timeDifference = deadline - now;

    if (timeDifference <= 0) {
      return { days: 0, hours: 0, minutes: 0, seconds: 0 };
    }

    const days = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
    const hours = Math.floor((timeDifference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    const minutes = Math.floor((timeDifference % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((timeDifference % (1000 * 60)) / 1000);

    return { days, hours, minutes, seconds };
  };

  const updateTimer = (campaignId) => {
    setCampaigns(prevCampaigns => {
      return prevCampaigns.map(campaign => {
        if (campaign.id === campaignId) {
          return {
            ...campaign,
            timer: calculateTimer(campaign.deadline_time)
          };
        }
        return campaign;
      });
    });
  };


  const formatTime = value => {
    return value < 10 ? `0${value}` : `${value}`;
  };

  const handleAcceptClick = (index) => {
    setShowOverlay(true);
    setSelectedRequest(filteredCampaigns[index].id);
  };
  console.log("selected request is", selectedRequest)

  const handleAcceptConfirm = (campaignId) => {
    const userid = userpofile ? JSON.parse(userpofile).id : null;
    // Perform actions when the user confirms acceptance
    // For example, you can send a request to the server or update state
    setShowOverlay(false);
    setSelectedRequest(null);

    axios.post('https://brich.youthevents.in/Bdashboard/accept-campaign/', { campaign_id: campaignId, email_id: userid })
      .then(response => {
        // If the campaign is accepted successfully, fetch updated campaigns
        console.log(response)
        setSelectedRequest(null);
        fetchCampaigns();
      })
      .catch(error => {
        console.error('Error accepting campaign:', error);
      });
  };

  const handleAcceptNotSure = () => {
    setShowOverlay(false);
    setSelectedRequest(null);
    // Handle actions when the user is not sure about accepting the request
  };

  const userpofile = localStorage.getItem("userProfile")




  const handleDownloadClick = async (campaignId) => {
    try {
      const response = await fetch(`https://brich.youthevents.in/Bdashboard/api/campaign-files/${campaignId}/`);
      if (response.ok) {
        const data = await response.json();
        if (data && data.files.length > 0) {
          downloadFiles(data.files);
        } else {
          console.log('No files found for the specified campaign ID.');
        }
      } else {
        console.error('Failed to fetch campaign files');
      }
    } catch (error) {
      console.error('Error fetching campaign files:', error);
    }
  };

  const downloadFiles = async (files) => {
    const downloadPromises = files.map(file => {
      return fetch(file.file_url)
        .then(async (response) => {
          if (response.ok) {
            const blob = await response.blob();
            const filename = file.file_name;
            const fileExt = filename.split('.').pop();
            saveAs(blob, filename); // Trigger download for each file
          } else {
            console.error(`Failed to download ${file.file_name}`);
          }
        })
        .catch((error) => {
          console.error(`Error downloading ${file.file_name}:`, error);
        });
    });

    await Promise.all(downloadPromises);
  };

  console.log("campaigns req are", campaigns)

  const filteredCampaigns = campaigns.filter(request => request.status === 'Pending' && request.is_approved);

  const [expandedItems, setExpandedItems] = useState(filteredCampaigns.map(() => false));


  return (

    <div className='requests cflex'>
      {filteredCampaigns.length == 0 ? (
        <>
          <h3 className='noab'>No Pending Request </h3>
        </>
      ) : (
        <>
          {filteredCampaigns.map((request, index) => (
            <>
              <div className={`request flex ${expandedItems[index] ? 'expanded' : ''}`}  >
                <div className="name">
                  <img src={pro} alt="" />
                  <h3>{request.creator_brand}</h3>
                </div>
                <div className="Campaignname">
                  {request.campaign_name}
                </div>
                <div className="time-left cflex">
                  <div className="left">Time Left</div>
                  <div className="time flex">

                    <div className="hour">{formatTime(request.timer.days)}</div>
                    :
                    <div className="hour">{formatTime(request.timer.hours)}</div>
                    :
                    <div className="min">{formatTime(request.timer.minutes)}</div>
                    :
                    <div className="sec">{formatTime(request.timer.seconds)}</div>
                  </div>
                </div>
                <div className="content-payout cflex">
                  <div className="payout"> <span className="black">Payout :</span> {request.payout} ₹</div>
                  <div className="content"> <span className="black"> Content :</span> {request.content} </div>

                </div>
                <div className="dropdown" onClick={() => handleDropdownClick(index)}>
                  <img src={down} alt="" />
                </div>
                {expandedItems[index] && (
                  <div className="additional-content cflex">
                    <p>
                      <span className="black">Task Description :</span>
                      {request.campaign_description}
                    </p>
                    <div className="type-goal flex">
                      <div className="type"><span className="black">Content Type :</span>{request.content_type}</div>
                      <div className="type"><span className="black">Goal :</span> {request.goal}</div>
                    </div>

                    {request.content == "Provided" && (

                      <div className="download download-request" onClick={() => handleDownloadClick(request.id)}>
                        Download Material
                      </div>
                    )}

                    <div className="accept-div flex">
                      <div className="accept" onClick={() => handleAcceptClick(index)}>Accept</div>
                    </div>

                  </div>
                )}

              </div>

              <div className={`mobile-request ${expandedItems[index] ? 'expanded' : ''}`} key={request.id}>
                <div className="mobile-heading flex">
                  <div className="name">
                    <img src={pro} alt="" />
                    <h3>{request.creator_brand}</h3>
                  </div>
                  <div className="dropdown" onClick={() => handleDropdownClick(index)}>
                    <img src={down} alt="" />
                  </div>
                </div>

                <div className="Campaignname">
                  {request.campaign_name}
                </div>
                <div className="time-left cflex">
                  <div className="left">Time Left</div>
                  <div className="time flex">

                    <div className="hour">{formatTime(request.timer.days)}</div>
                    :
                    <div className="hour">{formatTime(request.timer.hours)}</div>
                    :
                    <div className="min">{formatTime(request.timer.minutes)}</div>
                    :
                    <div className="sec">{formatTime(request.timer.seconds)}</div>
                  </div>
                </div>
                <div className="content-payout cflex">
                  <div className="payout"> <span className="black">Payout :</span> {request.payout} ₹</div>
                  <div className="content"> <span className="black"> Content :</span> {request.content} </div>

                </div>

                {expandedItems[index] && (
                  <div className="additional-content cflex">
                    <p>
                      <span className="black">Task Description :</span>
                      {request.campaign_description}
                    </p>
                    <div className="type-goal flex">
                      <div className="type"><span className="black">Content Type :</span>{request.content_type}</div>
                      <div className="type"><span className="black">Goal :</span> {request.goal}</div>
                    </div>
                    {request.content == "Provided" && (

                      <div className="download download-request" onClick={() => handleDownloadClick(request.id)}>
                        Download Material
                      </div>
                    )}

                    <div className="accept-div flex">
                      <div className="accept" onClick={() => handleAcceptClick(index)}>Accept</div>
                    </div>

                  </div>
                )}


              </div>


            </>
          ))}
          {showOverlay && selectedRequest && (
            <div className="overlay">
              <div className="overlay-content cflex">
                <div className="close" onClick={handleAcceptNotSure}><img src={close} alt="" /></div>
                <h3>Are you Sure ? You want to Join Campaign?</h3>
                <div className="buttons">
                  <div className='not-sure' onClick={handleAcceptNotSure}>Not Sure</div>
                  <div className='accept' onClick={() => handleAcceptConfirm(selectedRequest)}>Accept</div>
                </div>
              </div>
            </div>
          )}

        </>
      )}


    </div>
  )
}

export default Request

const requests = [
  {
    brandName: "Wildinz",
    campaignName: "WILDINZ LEAD GENERATION",
    payout: "4000",
    content: "provided",
    contentType: "reels",
    goal: "Leads Generation",
    taskDescription: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum."
  },
  {
    brandName: "XYZ Corporation",
    campaignName: "XYZ SOCIAL MEDIA PROMOTION",
    payout: "6000",
    content: "created",
    contentType: "videos",
    goal: "Brand Awareness",
    taskDescription: "Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit."
  },
  {
    brandName: "ABC Company",
    campaignName: "ABC PRODUCT LAUNCH",
    payout: "4500",
    content: "provided",
    contentType: "posts",
    goal: "Product Launch",
    taskDescription: "Nunc sagittis lacus vel augue laoreet rutrum faucibus dolor auctor. Vestibulum id ligula porta felis euismod semper. Nulla vitae elit libero, a pharetra augue. Nullam id dolor id nibh ultricies vehicula ut id elit. Morbi leo risus, porta ac consectetur ac, vestibulum at eros."
  },
  {
    brandName: "PQR Corporation",
    campaignName: "PQR SOCIAL MEDIA ADVERTISING",
    payout: "5500",
    content: "provided",
    contentType: "story",
    goal: "Increase Sales",
    taskDescription: "Pellentesque ornare sem lacinia quam venenatis vestibulum. Donec ullamcorper nulla non metus auctor fringilla. Aenean eu leo quam. Pellentesque ornare sem lacinia quam venenatis vestibulum. Curabitur blandit tempus porttitor."
  }
];