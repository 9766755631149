import React, { useEffect, useState } from 'react'
import "./campaign.css"
import pro from "../../media/pro.png"
import close from "../../media/close.svg"
import down from "../../media/down.svg"
import axios from "axios"
import JSZip from 'jszip';
import { saveAs } from 'file-saver';


const requests = [
  {
    brandName: "NewBrand",
    campaignName: "New Campaign 1",
    payout: "7000",
    content: "provided",
    contentType: "IGTV",
    goal: "Engagement",
    taskDescription: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
    status: "Incomplete"
  },
  {
    brandName: "AnotherCompany",
    campaignName: "Special Product Launch",
    payout: "8500",
    content: "created",
    contentType: "posts",
    goal: "Leads Generation",
    taskDescription: "Introducing a special product launch! Let's create podcasts to spread awareness and boost sales.",
    status: "Cancelled"

  },
  {
    brandName: "BrandABC",
    campaignName: "BrandABC Contest",
    payout: "5000",
    content: "created",
    contentType: "articles",
    goal: "User Participation",
    taskDescription: "Join the BrandABC Contest! Write articles and engage users for a chance to win exciting prizes.",
    status: "Under Review"

  },
  {
    brandName: "DigitalCorp",
    campaignName: "DigitalCorp Webinar",
    payout: "6000",
    content: "provided",
    contentType: "webinars",
    goal: "Leads Generation",
    taskDescription: "Hosting a webinar series for educational outreach purposes. Join us to learn and grow together.",
    status: "Successful"

  }
];


const Campaign = () => {
  const [showOverlay, setShowOverlay] = useState(false);
  const [selectedRequest, setSelectedRequest] = useState(null);
  const [timer, setTimer] = useState({
    hours: 24,
    minutes: 0,
    seconds: 0,
  });

  const [expandedItems, setExpandedItems] = useState(requests.map(() => false));

  const handleDropdownClick = (index) => {
    setExpandedItems(prevExpandedItems => {
      const newExpandedItems = [...prevExpandedItems];
      newExpandedItems[index] = !newExpandedItems[index];
      return newExpandedItems;
    });
  };

  const [campaigns, setCampaigns] = useState([]);
  const userpofile = localStorage.getItem("userProfile")


  useEffect(() => {
    fetchCampaigns();
  }, []);

  useEffect(() => {
    fetchCampaigns();
  }, []);

  useEffect(() => {
    const countdownIntervals = campaigns.map(campaign => {
      return setInterval(() => {
        updateTimer(campaign.id);
      }, 1000);
    });

    return () => {
      countdownIntervals.forEach(interval => clearInterval(interval));
    };
  }, [campaigns]);

  const fetchCampaigns = () => {
    
    const useridl = userpofile ? JSON.parse(userpofile).id : null;
    console.log("user idl", useridl)

    // axios.get('https://brich.youthevents.in/Bdashboard/retrieve_campaigns/', {
    axios.get('https://brich.youthevents.in/Bdashboard/get-all-request-data/', {
      headers: {
        'user': useridl,
      }
    }).then(response => {
      const updatedCampaigns = response.data.map(campaign => ({
        ...campaign,
        timer: calculateTimer(campaign.deadline_time)
      }));
      setCampaigns(updatedCampaigns);
    }).catch(error => {
      console.error('Error fetching campaigns:', error);
    });
  };

  const calculateTimer = (deadlineTime) => {
    const deadline = new Date(deadlineTime).getTime();
    const now = new Date().getTime();
    const timeDifference = deadline - now;

    if (timeDifference <= 0) {
      return { days: 0, hours: 0, minutes: 0, seconds: 0 };
    }

    const days = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
    const hours = Math.floor((timeDifference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    const minutes = Math.floor((timeDifference % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((timeDifference % (1000 * 60)) / 1000);

    return { days, hours, minutes, seconds };
  };

  const updateTimer = (campaignId) => {
    setCampaigns(prevCampaigns => {
      return prevCampaigns.map(campaign => {
        if (campaign.id === campaignId) {
          return {
            ...campaign,
            timer: calculateTimer(campaign.deadline_time)
          };
        }
        return campaign;
      });
    });
  };


  const formatTime = value => {
    return value < 10 ? `0${value}` : `${value}`;

  };

 
  // const fetchCampaigns = () => {

  //   const useridl = userpofile ? JSON.parse(userpofile).id : null;
  //   console.log("user idl", useridl)

  //   // axios.get('https://brich.youthevents.in/Bdashboard/retrieve_campaigns/', {
  //   axios.get('https://brich.youthevents.in/Bdashboard/get-all-request-data/', {
  //     headers: {
  //       'user': useridl,
  //     }
  //   }).then(response => {
  //     setCampaigns(response.data);
  //   })
  //     .catch(error => {
  //       console.error('Error fetching campaigns:', error);
  //     });
  // };



  const filteredCampaigns = campaigns.filter(request => request.status !== 'Pending' && request.status !== 'Set Payout');
  console.log("campaigns accepted are", filteredCampaigns)

  const [campaignLink, setCampaignLink] = useState('');

  const handleLinkInputChange = (event) => {
    setCampaignLink(event.target.value);
  };

  const [errors, setErrors] = useState(Array(filteredCampaigns.length).fill(''));

  const handleUploadLink = (index) => {
    
     if (campaignLink.trim() === '') {
    const newErrors = [...errors];
    newErrors[index] = 'Campaign link cannot be empty.';
    setErrors(newErrors);
    return;
  }
    const updatedCampaigns = [...campaigns];
    updatedCampaigns[index].isUploaded = true;
    setCampaigns(updatedCampaigns);
  };


  const handleCampaignSubmit = (index) => {

    if (campaignLink.trim() === '') {
      const newErrors = [...errors];
      newErrors[index] = 'Campaign link cannot be empty.';
      setErrors(newErrors);
      return;
    }

    const userEmailid = localStorage.getItem('userProfile') ? JSON.parse(localStorage.getItem('userProfile')).id : null;

    axios.post('https://brich.youthevents.in/Bdashboard/submit-campaign-link/', {
      campaign_id: filteredCampaigns[index].id,
      email_id: userEmailid,
      campaign_link: campaignLink, // Include the campaign link in the request
    })
      .then(response => {
        const updatedRequests = [...filteredCampaigns];
        updatedRequests[index].status = 'Under Review'; // Update status to 'Under Review' upon link submission
        setSelectedRequest(updatedRequests);
        console.log(response.data);
        fetchCampaigns();
        setShowOverlay(true);
        setSelectedRequest(filteredCampaigns[index].id);
      })
      .catch(error => {
        console.error('Error submitting campaign link:', error);
      });

  };

  const handleclosepopup = () => {
    setShowOverlay(false)
  }




  const getStatusColorClass = (status) => {
    switch (status) {
      case "Incomplete":
        return "orange"; // Define CSS for 'orange' color in your stylesheet
      case "Cancelled":
        return "red"; // Define CSS for 'red' color in your stylesheet
      case "Under Review":
      case "Successful":
        return "green"; // Define CSS for 'green' color in your stylesheet
      default:
        return "";
    }
  };

  const [isUploaded, setIsUploaded] = useState(false);



  const handleDownloadClick = async (campaignId) => {
    try {
      const response = await fetch(`https://brich.youthevents.in/Bdashboard/api/campaign-files/${campaignId}/`);
      if (response.ok) {
        const data = await response.json();
        if (data && data.files.length > 0) {
          downloadFiles(data.files);
        } else {
          console.log('No files found for the specified campaign ID.');
        }
      } else {
        console.error('Failed to fetch campaign files');
      }
    } catch (error) {
      console.error('Error fetching campaign files:', error);
    }
  };

  const downloadFiles = async (files) => {
    const downloadPromises = files.map(file => {
      return fetch(file.file_url)
        .then(async (response) => {
          if (response.ok) {
            const blob = await response.blob();
            const filename = file.file_name;
            const fileExt = filename.split('.').pop();
            saveAs(blob, filename); // Trigger download for each file
          } else {
            console.error(`Failed to download ${file.file_name}`);
          }
        })
        .catch((error) => {
          console.error(`Error downloading ${file.file_name}:`, error);
        });
    });

    await Promise.all(downloadPromises);
  };


  return (
    <div className='requests cflex'>
      {filteredCampaigns.length == 0 ? (
        <>
          <h3 className='noab'>new campaigns will be available soon </h3>
        </>
      ) : (
        <>
          {filteredCampaigns.map((request, index) => (
            <>
              <div className={`request flex ${expandedItems[index] ? 'expanded' : ''}`} key={request.id}>
                <div className="name">
                  <img src={pro} alt="" />
                  <h3>{request.creator_brand}</h3>
                </div>
                <div className="Campaignname">
                  {request.campaign_name}
                </div>
                <div className="time-left cflex">
                  <div className="left">Time Left</div>
                  <div className="time flex">

                  <div className="hour">{formatTime(request.timer.days)}</div>
                    :
                    <div className="hour">{formatTime(request.timer.hours)}</div>
                    :
                    <div className="min">{formatTime(request.timer.minutes)}</div>
                    :
                    <div className="sec">{formatTime(request.timer.seconds)}</div>
                  </div>
                </div>
                <div className="content-payout cflex">
                  <div className="payout"> <span className="black">Payout :</span> {request.payout} ₹</div>
                  <div className={`content status ${getStatusColorClass(request.status)}`}> <span className="black"> Status :</span> {request.status} </div>

                </div>
                <div className="dropdown" onClick={() => handleDropdownClick(index)}>
                  <img src={down} alt="" />
                </div>
                {expandedItems[index] && (
                  <div className="additional-content cflex">
                    <p>
                      <span className="black">Task Description :</span>
                      {request.campaign_description}
                    </p>
                    <div className="type-goal flex">
                      <div className="type"><span className="black">Content Type :</span>{request.content_type}</div>
                      <div className="type"><span className="black">Goal :</span> {request.goal}</div>
                      {request.content == "Provided" && (

                        <div className="download" onClick={() => handleDownloadClick(request.id)}>
                          Download Material
                        </div>
                      )}

                    </div>
                    {request.isUploaded ? (
                      <div className="upload flex uploaded">
                        <input type="text" value={campaignLink} disabled />
                        {/* <img src={close} alt="" className='cut'/> */}
                        <div className="upload-btn uploaded-btn"
                          style={{
                            backgroundColor: "grey", // Change color if isUploaded is true
                            pointerEvents: isUploaded ? 'none' : '', // Disable pointer events if isUploaded is true
                          }}
                        >Uploaded</div>
                      </div>
                    ) : (
                      <div className="upload flex">
                        <input
                          type="text"
                          value={campaignLink}
                          onChange={handleLinkInputChange}
                          placeholder="Enter campaign link"
                          readOnly={isUploaded} // Set readOnly based on isUploaded state
                        />
                        <div
                          className="upload-btn"
                          onClick={() => handleUploadLink(index)}

                        >
                          Upload
                        </div>
                        {errors[index] && <div className="error-msg">{errors[index]}</div>}

                      </div>
                    )}
                    <div className="accept-div flex">
                      <div className="accept" onClick={() => handleCampaignSubmit(index)} >Submit</div>
                    </div>

                  </div>
                )}

              </div>


              <div className={`mobile-request ${expandedItems[index] ? 'expanded' : ''}`} key={index}>
                <div className="mobile-heading flex">
                  <div className="name">
                    <img src={pro} alt="" />
                    <h3>{request.creator_brand}</h3>
                  </div>
                  <div className="dropdown" onClick={() => handleDropdownClick(index)}>
                    <img src={down} alt="" />
                  </div>
                </div>

                <div className="Campaignname">
                  {request.campaign_name}
                </div>
                <div className="time-left cflex">
                  <div className="left">Time Left</div>
                  <div className="time flex">

                  <div className="hour">{formatTime(request.timer.days)}</div>
                    :
                    <div className="hour">{formatTime(request.timer.hours)}</div>
                    :
                    <div className="min">{formatTime(request.timer.minutes)}</div>
                    :
                    <div className="sec">{formatTime(request.timer.seconds)}</div>
                  </div>
                </div>
                <div className="content-payout cflex">
                  <div className="payout"> <span className="black">Payout :</span> {request.payout} ₹</div>
                  <div className={`content status ${getStatusColorClass(request.status)}`}> <span className="black"> Status :</span> {request.status} </div>

                </div>


                {expandedItems[index] && (
                  <div className="additional-content cflex">
                    <p>
                      <span className="black">Task Description :</span>
                      {request.campaign_description}
                    </p>
                    <div className="type-goal flex">
                      <div className="type"><span className="black">Content Type :</span>{request.content_type}</div>
                      <div className="type"><span className="black">Goal :</span> {request.goal}</div>
                      {request.content == "Provided" && (
                        <div className="download" onClick={() => handleDownloadClick(request.id)}>
                          Download Material
                        </div>
                      )}

                    </div>
                    {request.isUploaded ? (
                      <div className="upload flex uploaded">
                        <input type="text" value={campaignLink} disabled />
                        {/* <img src={close} alt="" className='cut'/> */}

                        <div className="upload-btn uploaded-btn"
                          style={{
                            backgroundColor: "grey", // Change color if isUploaded is true
                            pointerEvents: isUploaded ? 'none' : '', // Disable pointer events if isUploaded is true
                          }}
                        >Uploaded</div>
                      </div>
                    ) : (
                      <div className="upload flex">
                        <input
                          type="text"
                          value={campaignLink}
                          onChange={handleLinkInputChange}
                          placeholder="Enter campaign link"
                          readOnly={isUploaded} // Set readOnly based on isUploaded state
                        />
                        <div
                          className="upload-btn"
                          onClick={() => handleUploadLink(index)}

                        >
                          Upload
                        </div>
                        {errors[index] && <div className="error-msg2">{errors[index]}</div>}
                      </div>
                    )}
                    <div className="accept-div flex">
                      <div className="accept" onClick={() => handleCampaignSubmit(index)} >Submit</div>
                    </div>

                  </div>
                )}


              </div>


            </>
          ))}
          {showOverlay && selectedRequest && (
            <div className="overlay">
              <div className="overlay-content cflex campaign-popup">
                <div className="close" onClick={handleclosepopup}><img src={close} alt="" /></div>
                <h3>Thank you for submitting your ticket! Our team is on it, and we'll get back to you shortly. Your request is important to us.</h3>

              </div>
            </div>
          )}
        </>
      )}


    </div>
  )
}

export default Campaign
