import React, { useEffect, useRef, useState } from 'react'
import "./profile.css"
import change from "../../media/change.png"
import pedit from "../../media/pedit.png"
import pencil from "../../media/pencil.svg"
import password from "../../media/password.svg"
import billing from "../../media/billing.svg"
import drop from "../../media/down.svg"
import close from "../../media/close.svg"
import addd from "../../media/addd.svg"
import sadd from "../../media/sadd.svg"
import tick from "../../media/tick.svg"
import OTPInput from 'react-otp-input'
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';

const SignupSchema = Yup.object().shape({
    accountHolderName: Yup.string().required('Account Holder Name is required'),
    accountNumber: Yup.string()
        .matches(/^[0-9]+$/, 'Must be only digits')
        .required('Account Number is required'),
    bankName: Yup.string().required('Bank Name is required'),
    confirmAccountNumber: Yup.string()
        .oneOf([Yup.ref('accountNumber'), null], 'Account Numbers must match')
        .required('Confirm Account Number is required'),
    branchCode: Yup.string().required('Branch Code is required'),
    ifscCode: Yup.string().required('IFSC Code is required'),
});

const Profile = () => {
    const [expandedsecurity, setExpandedsecurity] = useState(false);
    const [expandedbilling, setExpandedBilling] = useState(false);
    const [showConfirmation, setShowConfirmation] = useState(false);
    const [showConfirmationStepTwo, setShowConfirmationStepTwo] = useState(false);
    const [showWithdrawalPopup, setShowWithdrawalPopup] = useState(false);
    const [selectedWithdrawalOption, setSelectedWithdrawalOption] = useState('');
    const [showOTPInput, setShowOTPInput] = useState(false);
    const [enteredOTP, setEnteredOTP] = useState();
    const [showOptions, setShowOptions] = useState(false);
    const [selectedOptions, setSelectedOptions] = useState([]);
    const [showBankOption, setShowBankOption] = useState(false);
    const [showBankOptionOTP, setShowBankOptionOTP] = useState(false);
    const [bankDetails, setBankDetails] = useState({});

    const parseuser = localStorage.getItem("userProfile")
    const user = JSON.parse(parseuser)
    const initialValues = {
        accountHolderName: '',
        accountNumber: '',
        bankName: '',
        confirmAccountNumber: '',
        branchCode: '',
        ifscCode: '',

    };
    const [bankbalance, setBankbalance] = useState(0)
    const [fromdata, setFormData] = useState("")
    console.log("form data", fromdata)


    useEffect(() => {
        const fetchBankDetails = async () => {
            try {
                const response = await axios.get(`https://brich.youthevents.in/Cdashboard/update_bank_details/${user.id}/`);
                if (response.status === 200) {
                    setBankDetails(response.data.bank_details);
                } else {
                    console.error('Failed to retrieve bank details');
                }
            } catch (error) {
                console.error('Error fetching bank details:', error);
            }
        };

        fetchBankDetails();
    }, [user.id]);

    console.log("bank detail is ", bankDetails)

    const handleBankUpdate = async () => {

        try {
            // Make an API call to send the bank details data to the backend
            const response = await axios.post(`https://brich.youthevents.in/Cdashboard/update_bank_details/${user.id}/`, { fromdata, opt: enteredOTP });

            if (response.status === 200) {
                // Handle success or show a success message to the user
                setBankDetails(response.data.bank_details || {});
                setShowBankOptionOTP(false)

                console.log('Bank details saved successfully');
            } else {
                // Handle other scenarios if needed
                console.error('Failed to save bank details');
            }
        } catch (error) {
            // Handle error scenarios
            console.error('Error saving bank details:', error);
        }


    }


    const handleBankChange = () => {


        setShowBankOption(!showBankOption)
    }

    console.log("user email ", user.email_id)

    const handleBankDetailChange = async (value) => {
        setFormData(value)
        console.log("valuse", value)
        try {
            const response = await axios.post(`https://brich.youthevents.in/Cdashboard/send_otp/`, { email_id: user.email, id: user.id });
            if (response.status === 200) {
                setShowBankOptionOTP(true);
            } else {
                console.error('Failed to retrieve bank details');
            }
        } catch (error) {
            console.error('Error fetching bank details:', error);
        }
    };



    const handleAddClick = () => {
        setShowOptions(!showOptions);
    };

    const handleOptionClick = (option) => {
        if (selectedOptions.includes(option)) {
            setSelectedOptions(selectedOptions.filter((item) => item !== option)); // Unselect the option
        } else {
            setSelectedOptions([...selectedOptions, option]); // Select the option
        }
    };


    console.log("user id", user.id)
    const handleSaveClick = () => {
        const selectedOptionsString = selectedOptions.join(',');

        const accessToken = localStorage.getItem("token")
        axios.post(
            'https://brich.youthevents.in/Cdashboard/update-content-type/',
            { content_type: selectedOptionsString, user: user.id },
            {
                headers: {
                    'Authorization': `Bearer ${accessToken}`,
                    'Content-Type': 'application/json',

                },
            }
        )
            .then(response => {
                if (response.status === 200) {
                    console.log('Content type updated successfully');
                    setShowOptions(false);
                } else {
                    console.error('Failed to update content type');
                    // Handle error scenarios here
                }
            })
            .catch(error => {
                console.error('Error:', error);
                // Handle network errors here
            });

    };
    useEffect(() => {
        // Fetch creator's content type and set the UI accordingly
        const fetchCreatorContentType = async () => {
            try {
                const accessToken = localStorage.getItem('token');
                const userpofile = localStorage.getItem("userProfile")

                const userid = userpofile ? JSON.parse(userpofile).id : null;

                const response = await axios.get(
                    'https://brich.youthevents.in/Cdashboard/get-creator-content-type',
                    {
                        headers: {
                            'user': userid,
                        }
                    }

                );

                if (response.data && response.data.content_type) {
                    setSelectedOptions(response.data.content_type.split(','));
                }
            } catch (error) {
                console.error('Error fetching creator content type:', error);
                // Handle error scenarios here
            }
        };

        fetchCreatorContentType();
    }, []);






    const handleWithdrawalRequest = () => {
        setShowWithdrawalPopup(true);
    };

    const handleWithdrawalOptionSelect = (option) => {
        setSelectedWithdrawalOption(option);
    };

    const handleContinueWithdrawal = () => {
        if (selectedWithdrawalOption === 'UPI') {
            // Perform actions specific to UPI withdrawal
            setShowOTPInput(true); // Show OTP input after selecting UPI
            setShowWithdrawalPopup(false)
        } else if (selectedWithdrawalOption === 'Bank') {
            // Perform actions specific to Bank withdrawal
            setShowOTPInput(true); // Show OTP input after selecting Bank
            setShowWithdrawalPopup(false)

        }
    };

    const handleOTPConfirmation = () => {
        handleWithdrawal();
        setShowOTPInput(false); // Hide OTP input after confirmation
    };
    const handleCloseOTP = () => {
        setShowOTPInput(false); // Hide OTP input after confirmation
    };
    const handleCloseBankOption = () => {
        setShowWithdrawalPopup(false); // Hide OTP input after confirmation
    };

    const handleRequestChange = () => {
        setShowConfirmation(true);
    };

    const handleConfirmChange = () => {
        const accessToken = localStorage.getItem('token');
        const user = JSON.parse(localStorage.getItem('userProfile')); // Parse the userProfile JSON

        axios.post(
            'https://brich.youthevents.in/Cdashboard/change-category-request/',
            {
                user: user.id, // Include user ID in the request data
                // Add other necessary data like new_category, etc., if needed
            },
            // {
            //     headers: {
            //         'Authorization': `Bearer ${accessToken}`,
            //         'Content-Type': 'application/json',
            //     },
            // }
        )
            .then(response => {
                if (response.status === 200) {
                    setShowConfirmation(false);
                    setShowConfirmationStepTwo(true);
                } else {
                    console.error('Failed to send category change request');
                    // Handle error scenarios here
                }
            })
            .catch(error => {
                console.error('Error:', error);
                // Handle network errors here
            });
    };

    const handleCancelChange = () => {
        setShowConfirmation(false);
    };

    const handleConfirmStepTwo = () => {
        setShowConfirmationStepTwo(false);
    };


    const expandSecurity = () => {
        setExpandedsecurity(!expandedsecurity)
    }

    const expandBilling = () => {
        setExpandedBilling(!expandedbilling)
    }


    useEffect(() => {
        const getBankDetails = async () => {
            const userpofile = localStorage.getItem("userProfile")

            const userid = userpofile ? JSON.parse(userpofile).id : null;
            try {
                const response = await axios.get(`https://brich.youthevents.in/Cdashboard/api/creator/${userid}/bank-details/`, {
                    headers: {
                        'user': userid,
                    }
                }
                );

                if (response.status === 200) {
                    const bankDetails = response.data;
                    console.log('Bank Details: issss', bankDetails);
                    setBankbalance(bankDetails.bank_balance)
                    // Handle the bank details received from the API
                } else {
                    console.error('Failed to fetch bank details');
                    // Handle the error response
                }
            } catch (error) {
                console.error('An error occurred:', error);
                // Handle network errors or exceptions
            }
        };

        getBankDetails();
    }, []);


    const [withdrawAmount, setWithdrawAmount] = useState('');

    const handleWithdrawal = () => {
        // Replace 'creator_id' with the actual ID of the user

        axios.post(`https://brich.youthevents.in/Cdashboard/withdraw/${user.id}/`, { amount: withdrawAmount })
            .then(response => {
                console.log(response.data);
                setBankbalance(response.data.updated_balance);
            })
            .catch(error => {
                console.error('Error:', error);
            });
    };



    // Call the function to get bank details

    const [isEditing, setIsEditing] = useState(false);
    const [editedUser, setEditedUser] = useState({
        name: user.name,
        email: user.email,
        phone: user.phone,
    });

    const handleEdit = () => {
        setIsEditing(true);
    };

    const handleSave = () => {
        const accessToken = localStorage.getItem('token');
        const userProfile = JSON.parse(localStorage.getItem('userProfile')); // Parse the userProfile JSON

        axios.post(
            'https://brich.youthevents.in/Cdashboard/update-user-details/',
            {
                user_id: userProfile.id,
                name: editedUser.name,
                email: editedUser.email,
                phone: editedUser.phone,
            },
            // {
            //     headers: {
            //         'Authorization': `Bearer ${accessToken}`,
            //         'Content-Type': 'application/json',
            //     },
            // }
        )
            .then(response => {
                if (response.status === 200) {
                    // Handle success
                    setIsEditing(false);

                    console.log('User details updated successfully');
                } else {
                    // Handle error scenarios
                    console.error('Failed to update user details');
                }
            })
            .catch(error => {
                // Handle network errors
                console.error('Error:', error);
            });

        // After successfully updating, switch back to non-editable mode
    };


    const handleChange = (e) => {
        const { name, value } = e.target;
        setEditedUser({ ...editedUser, [name]: value });
    };

    const [selectedFile, setSelectedFile] = useState(null);
    const [profilepic, setProfilepic] = useState(null);
    const fileInputRef = useRef(null);

    const handleFileChange = (event) => {
        setSelectedFiles(event.target.files[0]);
        setSelectedFile(event);
    };

    const setSelectedFiles = (file) => {
        setSelectedFile(file);
    };

    const handleUpload = () => {
        if (selectedFile) {
            const accessToken = localStorage.getItem('token');
            const userpofile = localStorage.getItem("userProfile");
            const userid = userpofile ? JSON.parse(userpofile).id : null;

            const formData = new FormData();
            formData.append('profile_picture', selectedFile);

            axios.post('https://brich.youthevents.in/Cdashboard/user-profile/', formData, {
                headers: {
                    'user': userid,
                    'Content-Type': 'multipart/form-data',
                },
            })
                .then(response => {
                    if (response.status === 200) {
                        console.log('Profile picture uploaded successfully');
                        fetchUserProfile();
                    } else {
                        console.error('Failed to upload profile picture');
                    }
                })
                .catch(error => {
                    console.error('Error:', error);
                });
        } else {
            console.error('No file selected for upload.');
        }
    };
    useEffect(() => {
        handleUpload()

    }, [selectedFile]);


    const handlePeditClick = () => {
        fileInputRef.current.click();
    };

    const fetchUserProfile = () => {
        const accessToken = localStorage.getItem('token');
        const userProfile = JSON.parse(localStorage.getItem('userProfile')); // Parse the userProfile JSON

        const userpofile = localStorage.getItem("userProfile")
        const userid = userpofile ? JSON.parse(userpofile).id : null;

        axios.get(`https://brich.youthevents.in/Cdashboard/user-profile/`, {




            headers: {
                'user': userid,
            }


            // headers: {
            //     'Authorization': `Bearer ${accessToken}`,
            //     'user': userProfile.id,
            // },
        })
            .then(response => {
                if (response.status === 200) {
                    // Handle successful response - profile fetched
                    console.log('User Profile:', response.data);
                    setProfilepic(response.data.profile_picture)
                } else {
                    // Handle error scenarios
                    console.error('Failed to fetch user profile');
                }
            })
            .catch(error => {
                // Handle network errors
                console.error('Error:', error);
            });
    };

    useEffect(() => {

        fetchUserProfile();
    }, []);

    const [currentPassword, setCurrentPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmNewPassword, setConfirmNewPassword] = useState('');

    const handleUpdatePassword = async () => {
        // Perform password validation checks
        const userpofile = localStorage.getItem("userProfile")

        const userid = userpofile ? JSON.parse(userpofile).id : null;

        const data = {
            currentPassword,
            newPassword,
            confirmNewPassword,
            userid
        };

        try {
            const response = await axios.post('https://brich.youthevents.in/Cdashboard/change-password/', data, {
                headers: {
                    'Content-Type': 'application/json',
                    // Authorization: `Bearer ${localStorage.getItem('token')}`,

                }
            });

            // Handle response based on success or failure
            if (response.status === 200) {
                // Password changed successfully, handle the response accordingly
            } else {
                // Failed to change password, handle the error response
            }
        } catch (error) {
            // Handle network errors or other exceptions
        }
    };

    const [transactions , setTransactions] = useState([])


    return (
        <div className='profile1 cflex'>
            <div className="basic-content flex">
                <div className="basic cflex">
                    <div className="basic-info flex">
                        BASIC INFO
                    </div>
                    <div className="profile-change">
                        <input
                            type="file"
                            ref={fileInputRef}
                            style={{ display: 'none' }}
                            onChange={handleFileChange}
                        />
                        {profilepic ? (
                            <div className="profileWrapper">
                                <img src={profilepic} alt="" className='dp' />
                            </div>
                        ) : (
                            <img src={change} alt="" />
                        )}
                        <img src={pedit} alt="" className="pedit" onClick={handlePeditClick} />
                    </div>
                    <div className="feilds flex">
                        <div className="unchange cflex">
                            <input type="text" value={user.creator_id} readOnly />

                            <div className="pencil">
                                <input
                                    type="text"
                                    name="name"
                                    value={editedUser.name}
                                    onChange={handleChange}
                                    disabled={!isEditing}
                                />
                                {!isEditing ? (
                                    <img src={pencil} alt="Edit" onClick={handleEdit} />
                                ) : (
                                    <img src={tick} alt="Save" onClick={handleSave} />
                                )}                            </div>
                        </div>
                        <div className="change cflex">
                            <div className="pencil">
                                <input
                                    type="text"
                                    name="email"
                                    value={editedUser.email}
                                    onChange={handleChange}
                                    disabled={!isEditing}
                                />
                                {!isEditing ? (
                                    <img src={pencil} alt="Edit" onClick={handleEdit} />
                                ) : (
                                    <img src={tick} alt="Save" onClick={handleSave} />
                                )}                            </div>
                            <div className="pencil">
                                <input
                                    type="text"
                                    name="phone"
                                    value={editedUser.phone}
                                    onChange={handleChange}
                                    disabled={!isEditing}
                                />
                                {!isEditing ? (
                                    <img src={pencil} alt="Edit" onClick={handleEdit} />
                                ) : (
                                    <img src={tick} alt="Save" onClick={handleSave} />
                                )}
                            </div>

                        </div>
                    </div>

                </div>
                <div className="Content cflex">
                    <div className="basic-info flex">
                        CONTENT
                    </div>
                    <div className="category-select">



                        <div className="Category cflex">
                            <h3>Category</h3>
                            <input type="text" value={'Fashion'} readOnly />
                            <p onClick={handleRequestChange}>request to change</p>
                        </div>

                        <div className="select-type cflex">
                            <h3>Select Content Type</h3>
                            <p>Select the Perfect Content Type to Elevate Your Profile</p>
                            <div className="contents flex">

                                {!showOptions && (<div className="addd" onClick={handleAddClick}><img src={addd} alt="" /></div>)}
                                {showOptions && (
                                    <div className="options flex">
                                        <div className="option-list flex">
                                            <div
                                                onClick={() => handleOptionClick('Reels')}
                                                className={selectedOptions.includes('Reels') ? 'selected' : ''}
                                            >
                                                <h3>Reels</h3>
                                                {selectedOptions.includes('Reels') ? (
                                                    <img src={tick} alt="Selected" />
                                                ) : (
                                                    <img src={sadd} alt="Add" />
                                                )}
                                            </div>
                                            <div
                                                onClick={() => handleOptionClick('Post')}
                                                className={selectedOptions.includes('Post') ? 'selected' : ''}
                                            >
                                                <h3>Post</h3>
                                                {selectedOptions.includes('Post') ? (
                                                    <img src={tick} alt="Selected" />
                                                ) : (
                                                    <img src={sadd} alt="Add" />
                                                )}
                                            </div>
                                            <div
                                                onClick={() => handleOptionClick('Story')}
                                                className={selectedOptions.includes('Story') ? 'selected' : ''}
                                            >
                                                <h3>Story</h3>
                                                {selectedOptions.includes('Story') ? (
                                                    <img src={tick} alt="Selected" />
                                                ) : (
                                                    <img src={sadd} alt="Add" />
                                                )}
                                            </div>
                                        </div>
                                        <div className='save flex' onClick={handleSaveClick}>Save</div>

                                    </div>
                                )}
                                {!showOptions && selectedOptions.length > 0 && (
                                    <div className="selected-options flex">
                                        {selectedOptions.map((option, index) => (
                                            <div key={index}>{option}</div>
                                        ))}
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                    {/* Overlay popup for step 1 confirmation */}
                    {showConfirmation && (
                        <div className="overlay">
                            <div className="overlay-content change-category cflex">
                                <div className="close " onClick={handleCancelChange}><img src={close} alt="" /></div>

                                <h3>Are you Sure ? You want to Change Category</h3>
                                <div className="buttons">
                                    <div className='not-sure' onClick={handleCancelChange}>Cancel</div>
                                    <div className='accept' onClick={handleConfirmChange}>Change</div>
                                </div>
                            </div>
                        </div>
                    )}

                    {/* Overlay popup for step 2 confirmation */}
                    {showConfirmationStepTwo && (
                        <div className="overlay">
                            <div className="overlay-content thank-change cflex">
                                <div className="close " onClick={handleConfirmStepTwo}><img src={close} alt="" /></div>

                                <h3>Your Request has been Submitted! Our team is on it, and we'll get back to you shortly. Your request is important to us.</h3>
                            </div>
                        </div>
                    )}
                </div>

            </div>
            <div className="pass-security cflex">
                <div className="general-head flex">
                    <h3><img src={password} alt="" /> Password & Security </h3>
                </div>
                <div className="para-drop flex">
                    <p>Fortify your Passwords and Security Measures for Digital Protection</p>
                    <img src={drop} alt="" onClick={expandSecurity} />
                </div>
                {expandedsecurity && (
                    <div className="passwords flex">
                        <input
                            type="password"
                            placeholder="Current Password"
                            value={currentPassword}
                            onChange={e => setCurrentPassword(e.target.value)}
                        />
                        <input
                            type="password"
                            placeholder="New Password"
                            value={newPassword}
                            onChange={e => setNewPassword(e.target.value)}
                        />
                        <input
                            type="password"
                            placeholder="Confirm New Password"
                            value={confirmNewPassword}
                            onChange={e => setConfirmNewPassword(e.target.value)}
                        />
                        <div className="greens" onClick={handleUpdatePassword}>
                            Update
                        </div>
                    </div>
                )}


            </div>

            <div className="pass-security cflex">
                <div className="general-head flex">
                    <h3><img src={billing} alt="" /> Billing AND TRANSACTION </h3>
                </div>
                <div className="para-drop flex">
                    <p>Streamline your Financial Practices for Billing and Secure Transactions</p>
                    <img src={drop} alt="" onClick={expandBilling} />
                </div>
                {expandedbilling && (
                    <>
                        <div className="balance-bank flex">
                            <div className="balance cflex">
                                <div className="balance-cover">
                                    <div className="balance-left">
                                        BALANCE :
                                        <span className="gold">{bankbalance} ₹</span>
                                    </div>
                                    <div className="withdraw">
                                        <div className="balance-withdraw flex">
                                            <input
                                                type="number"
                                                placeholder="Enter amount to withdraw"
                                                value={withdrawAmount}
                                                onChange={(e) => setWithdrawAmount(e.target.value)}
                                            />
                                            <div className="greens greensmall" onClick={handleWithdrawalRequest}>Withdraw</div>
                                        </div>
                                        <p>in Rupees (₹)</p>
                                    </div>
                                    <div className="greens greensmall2 greens3" onClick={handleWithdrawalRequest}>Withdraw</div>

                                </div>
                            </div>
                            {showWithdrawalPopup && (
                                <div className="overlay">
                                    <div className="overlay-content withdrawal-popup cflex">
                                        <div className="close " onClick={() => setShowWithdrawalPopup(false)}><img src={close} alt="" /></div>

                                        <h3>How do you want to withdraw money?</h3>
                                        <div className="withdrawal-options flex">
                                            <div className='woption'>
                                                <input
                                                    type="radio"
                                                    value="UPI"
                                                    checked={selectedWithdrawalOption === 'UPI'}
                                                    onChange={() => handleWithdrawalOptionSelect('UPI')}
                                                />
                                                UPI
                                            </div>
                                            <div className='woption'>
                                                <input
                                                    type="radio"
                                                    value="Bank"
                                                    checked={selectedWithdrawalOption === 'Bank'}
                                                    onChange={() => handleWithdrawalOptionSelect('Bank')}
                                                />
                                                Bank
                                            </div>
                                        </div>
                                        <div className="buttons">
                                            <div className='greens' onClick={handleContinueWithdrawal}>Continue</div>
                                        </div>
                                    </div>
                                </div>
                            )}

                            {/* OTP input screen */}
                            {showOTPInput && (
                                <div className="overlay">
                                    <div className="overlay-content otp-input cflex">
                                        <div className="close " onClick={() => setShowOTPInput(false)}><img src={close} alt="" /></div>

                                        <h3>An OTP has been sent to your email please check your inbox</h3>

                                        <OTPInput
                                            value={enteredOTP}
                                            onChange={(e) => setEnteredOTP(e?.target?.value)}
                                            numInputs={4}
                                            renderSeparator={<span style={{ width: "12px" }}> </span>}
                                            isInputNum={true}
                                            shouldAutoFocus={true}
                                            renderInput={(props) => <input {...props} />}
                                            inputStyle={{
                                                border: "0.5px solid #000",
                                                borderRadius: "8px",
                                                width: "55px",
                                                height: "55px",
                                                fontSize: "18px",
                                                color: "#000",
                                                fontWeight: "400",
                                                caretColor: "blue",
                                                backgroundColor: "#EEE"
                                            }}
                                            focusStyle={{
                                                border: "1px solid #CFD3DB",
                                                outline: "none"
                                            }}
                                        />
                                        <div className="buttons">
                                            <div className='confirm greens' onClick={handleOTPConfirmation}>Confirm</div>
                                        </div>
                                    </div>
                                </div>
                            )}


                            <div className="bank cflex">
                                <div className="account-detail">
                                    BANK DETAILS :
                                </div>
                                <div className="all-details">
                                    <h3> <span className="bold">Account Holder’s Name :</span> {bankDetails.account_holder_name || '---'} </h3>
                                    <h3> <span className="bold">BANK NAME :</span> {bankDetails.bank_name || '---'} </h3>
                                    <h3> <span className="bold">ACCOUNT NO :</span> {bankDetails.account_number || '---'} </h3>
                                    <h3> <span className="bold">BRANCH CODE :</span> {bankDetails.branch_code || '---'} </h3>
                                    <h3> <span className="bold">IFSC CODE :</span> {bankDetails.ifsc_code || '---'} </h3>
                                </div>
                                <div className="change-div flex">
                                    <div className="greens" onClick={handleBankChange}>{(bankDetails.account_holder_name === null || bankDetails.account_number === null || bankDetails.bank_name === null || bankDetails.branch_code === null || bankDetails.ifsc_code === null) ? 'Add' : 'Change'}</div>
                                </div>

                            </div>


                        </div>
                        {showBankOption && (

                            <Formik
                                initialValues={initialValues}
                                validationSchema={SignupSchema}
                                onSubmit={(values) => {
                                    handleBankDetailChange(values);
                                }}
                            >
                                {({ errors, touched }) => (
                                    <Form className="update-bank cflex">
                                        <div className="detail-update flex">
                                            <h3>BANK DETAILS :</h3>
                                            <button type='submit' className="greens update1" >Update</button>
                                        </div>
                                        <div className="bank-feild flex">

                                            <div className="one">
                                                <div className="error-wrap">

                                                    <Field type="text" name="accountHolderName" placeholder='Account Holder Name' />
                                                    <ErrorMessage name="accountHolderName" component="div" className='berror' />
                                                </div>
                                                <div className="error-wrap">

                                                    <Field type="text" name="accountNumber" placeholder='Account No.' />
                                                    <ErrorMessage name="accountNumber" component="div" className='berror' />
                                                </div>
                                            </div>

                                            <div className="two" >
                                                <div className="error-wrap">

                                                    <Field type="text" name="bankName" placeholder='Enter Bank Name' />
                                                    <ErrorMessage name="bankName" component="div" className='berror' />
                                                </div>
                                                <div className="error-wrap">

                                                    <Field type="text" name="confirmAccountNumber" placeholder='Confirm Account No.' />
                                                    <ErrorMessage name="confirmAccountNumber" component="div" className='berror' />
                                                </div>
                                            </div>

                                            <div className="three">
                                                <div className="error-wrap">

                                                    <Field type="text" name="branchCode" placeholder='Branch Code' />
                                                    <ErrorMessage name="branchCode" component="div" className='berror' />
                                                </div>
                                                <div className="error-wrap">

                                                    <Field type="text" name="ifscCode" placeholder='IFSC Code' />
                                                    <ErrorMessage name="ifscCode" component="div" className='berror' />
                                                </div>
                                            </div>


                                            <button type='submit' className="greens update2"  >Update</button>


                                        </div>
                                    </Form>
                                )}
                            </Formik>
                        )}
                        {showBankOptionOTP && (
                            <div className="overlay">
                                <div className="overlay-content otp-input cflex">
                                    <div className="close " onClick={() => setShowBankOptionOTP(false)}><img src={close} alt="" /></div>

                                    <h3>An OTP has been sent to your email please check your inbox</h3>

                                    <OTPInput
                                        value={enteredOTP}
                                        onChange={(value) => setEnteredOTP(value)}
                                        numInputs={4}
                                        renderSeparator={<span style={{ width: "12px" }}> </span>}
                                        isInputNum={true}
                                        shouldAutoFocus={true}
                                        renderInput={(props) => <input {...props} />}
                                        inputStyle={{
                                            border: "0.5px solid #000",
                                            borderRadius: "8px",
                                            width: "55px",
                                            height: "55px",
                                            fontSize: "18px",
                                            color: "#000",
                                            fontWeight: "400",
                                            caretColor: "blue",
                                            backgroundColor: "#EEE"
                                        }}
                                        focusStyle={{
                                            border: "1px solid #CFD3DB",
                                            outline: "none"
                                        }}
                                    />
                                    <div className="buttons">
                                        <div className='confirm greens' onClick={handleBankUpdate}>Confirm</div>
                                    </div>
                                </div>
                            </div>
                        )}


                        <Formik
                            initialValues={{ upi_Id: bankDetails.upi_Id || '' }} // Set initial value for the UPI ID
                            validationSchema={Yup.object().shape({
                                upi_Id: Yup.string().required('UPI ID is required'),
                            })}
                            onSubmit={(values) => {
                                // Logic to handle the submission of UPI ID form data
                                handleBankDetailChange(values);
                            }}
                        >
                            {({ errors, touched }) => (
                                <Form className="upi flex">
                                    <div className="upiid">
                                        YOUR UPI ID:
                                    </div>
                                    <div className="upi-input flex">
                                        <Field name='upi_Id' type="text" placeholder={bankDetails.upi_Id || '---'} />
                                        {errors.upi_Id && touched.upi_Id && (
                                            <div className='uerror'>{errors.upi_Id}</div>
                                        )}
                                    </div>
                                    <button type='submit' className="greens" >{(bankDetails.upi_Id === null) ? 'Add' : 'Change'}</button>
                                </Form>
                            )}
                        </Formik>



                        <div className="history-cover">
                            <div className="withdraw-history">
                                <div className="history-head">Withdrawal History</div>
                                <div className="hhead flex">
                                    <h3 style={{ marginLeft: "40px" }}>Transaction Status</h3>
                                    <h3>Transaction Date</h3>
                                    <h3>Transaction Date</h3>
                                    <h3 style={{ maxWidth: "265px", width: "100%" }}>Transaction Status</h3>
                                </div>
                                <div className="transactions notrans cflex">
                                    {transactions.length === 0 ? (
                                        <>
                                            <h3> No Transactions's Yet</h3>
                                        </>
                                    ) : (
                                        <>
                                            {transactions.map((transaction, index) => (
                                                <div className='trans flex'>
                                                    <div className="accountno">{transaction.accountno}</div>
                                                    <div className="amount">{transaction.amount}</div>
                                                    <div className="transactiondate">{transaction.transactionDate}</div>
                                                    <div className="transactionstatus">{transaction.transactionStatus}</div>
                                                </div>
                                            ))}
                                        </>
                                    )}

                                </div>

                            </div>
                        </div>
                    </>

                )}


            </div>

        </div >
    )
}

export default Profile

// const transactions = [
//     {
//         accountno: "45215513477",
//         amount: "₹ 4000",
//         transactionDate: "16 Sep 2023",
//         transactionStatus: "InProgress (will take 24-32 hours)"
//     },
//     {
//         accountno: "45215513477",
//         amount: "₹ 4000",
//         transactionDate: "16 Sep 2023",
//         transactionStatus: "InProgress (will take 24-32 hours)"
//     },
//     {
//         accountno: "45215513477",
//         amount: "₹ 4000",
//         transactionDate: "16 Sep 2023",
//         transactionStatus: "Successful"
//     },
//     {
//         accountno: "45215513477",
//         amount: "₹ 4000",
//         transactionDate: "16 Sep 2023",
//         transactionStatus: "Failed ( Bank Issue, Try Again Later )"
//     },
//     {
//         accountno: "45215513477",
//         amount: "₹ 4000",
//         transactionDate: "16 Sep 2023",
//         transactionStatus: "Successful"
//     },
//     {
//         accountno: "45215513477",
//         amount: "₹ 4000",
//         transactionDate: "16 Sep 2023",
//         transactionStatus: "InProgress (will take 24-32 hours)"
//     },
//     {
//         accountno: "45215513477",
//         amount: "₹ 4000",
//         transactionDate: "16 Sep 2023",
//         transactionStatus: "InProgress (will take 24-32 hours)"
//     },
//     {
//         accountno: "45215513477",
//         amount: "₹ 4000",
//         transactionDate: "16 Sep 2023",
//         transactionStatus: "InProgress (will take 24-32 hours)"
//     },
// ]