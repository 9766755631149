import { useEffect, useState } from 'react';
import './App.css';
import Navbar from './Components/Navbar/Navbar';
import Dashboard from './Components/Dashboard/Dashboard';
import Request from './Components/Requests/Request';
import Campaign from './Components/My Campaign/Campaign';
import HelpAndSupport from './Components/Help and support/HelpAndSupport';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Profile from './Components/User Profile/Profile';
import Signin from './Components/Sign in flow/Signin';
import ForgetPassword from './Components/Sign in flow/ForgetPassword';
import GetAccess from './Components/GetAccess/GetAccess';


function App() {



  const [activeComponent, setActiveComponent] = useState('Dashboard');
  // const [isUserAuthenticated, setIsUserAuthenticated] = useState(false);

  // useEffect(() => {
  //   // Check if user is already authenticated (e.g., with a token in localStorage)
  //   const token = localStorage.getItem('token');
  //   if (token) {
  //     setIsUserAuthenticated(true);
  //   }
  // }, []);

  const isUserAuthenticated = localStorage.getItem('isUserAuthenticated');
  const userProfile = JSON.parse(localStorage.getItem('userProfile'));

  // if (isUserAuthenticated === 'true' && userProfile) {
  //     dispatch(setUserAuthenticated(true));
  //     dispatch(setUserProfile(userProfile));
  // }

    useEffect(() => {
    
  }, [isUserAuthenticated]);


  const renderActiveComponent = () => {
    switch (activeComponent) {
      case 'Dashboard':
        return <Dashboard />;
      case 'myCampaign':
        return <Campaign />;
      case 'Requests':
        return <Request />;
      case 'Help':
        return <HelpAndSupport />;
      case 'Profile':
        return <Profile />;
      default:
        return <Dashboard />;
    }
  };

  return (
    <BrowserRouter>
      <div className="App">
        <Routes>

         <Route
            path="/"
            element={
              isUserAuthenticated ? (
                <>
                  <Navbar setActiveComponent={setActiveComponent}  />
                  {renderActiveComponent()}
                </>
              ) : (
                <Signin  />
              )
            }
          />

        
          <Route path="/forgot-password"
            element={[
             <ForgetPassword/>
            ]} />

          <Route path="/get-creator-access"
            element={[
             <GetAccess/>
            ]} />
            
        </Routes>
      </div>
    </BrowserRouter>);
}

export default App;
